import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import SignupForm from "../../services/forms/signup";

import BannerAuthenticate from "../../assets/banners/authenticate.jpg";

function Signup() {
  const [title] = useState("Cadastre-se - Tabelionato Camboriú");
  const [page] = useState("/cadastre-se");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerAuthenticate}) no-repeat`} description="Cadastre-se para ter acesso a retirada de senhas." title="Cadastre-se" />
        <section className="section-default section-signup">
          <Container>
            <Row className="justify-content-md-center">
              <Col className="section-signup-form" xs={12} md={8}>
                <SignupForm />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="section-signup-authenticate" xs={12} md={8}>
                <a href="/autenticar">Já tenho um cadastro!</a>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Signup;
