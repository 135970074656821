import React from "react";

class Images extends React.Component {
  render({ image }) {
    if(image !== undefined) {
      if(image.url !== undefined) {
        return image.url;
      }
      return encodeURI(`${process.env.REACT_APP_APP_API_UPLOADS_URL}/${image.key}`);
    }
  }
}

export default new Images();
