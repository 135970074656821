import React from "react";

class Storage extends React.Component {
  constructor() {
    super();
    this.state = {
      prefix: "@tcs_",
    };
  }

  set(key, value) {
    key = `${this.state.prefix}${key}`;
    console.log(key);
    localStorage.setItem(key, value);
  }

  get(key) {
    key = `${this.state.prefix}${key}`;
    return localStorage.getItem(key);
  }

  remove(key) {
    key = `${this.state.prefix}${key}`;
    return localStorage.removeItem(key);
  }
}

export default new Storage();
