import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerOurServices from "../../assets/banners/our-services.jpg";

function PrivacyPolicy(props) {
  const [title] = useState("Política de Privacidade - Tabelionato Camboriú");
  const [page] = useState("/politica-de-privacidade");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerOurServices}) no-repeat`} title="Política de privacidade" />
        <section className="section-default section-our-services">
          <Container>
            <Row>
              <Col className="privacy-policy-content-box" xs={12}>
                <h2 className="default-sub-title half"><a href="https://www.tabelionatocamboriu.com.br">Política de privacidade Tabelionato de Notas e Protestos de Camboriú</a></h2>
                <div className="default-sub-title-separator" />
                
				<p className="default-sub-description text-justify">O respeito que temos por sua privacidade é um dos requisitos que mais nos apegamos para crescer, construímos nossos serviços nos baseando em sólidos princípios de segurança.</p>				
				<p className="default-sub-description text-justify">A Three Pixels Sistemas oferece dentro do aplicativo Tabelionato de Notas e Protestos de Camboriú o serviço de chamada de senhas em Camboriú. Nossa Política de Privacidade é o que usamos para explicar nossas práticas durante o tratamento de dados do usuário.</p>
				
				<h2 className="default-sub-title half">Dados que usamos</h2>
				<div className="default-sub-title-separator" />
				
				<p className="default-sub-description text-justify">Localização. Usamos o serviço de localização para saber a distância entre você e o cartório, conseguindo assim saber se é possível chegar a tempo de não perder a sua vez.</p>
				
				<p className="default-sub-description text-justify">Acesso a internet. Por se tratar de um aplicativo que consome uma API é necessário estar em constante comunicação com o servidor para autenticar as ações e atualizar os dados em tempo real.</p>
				
				<h2 className="default-sub-title half">Como usamos os dados</h2>
				<div className="default-sub-title-separator" />
				
				<p className="default-sub-description text-justify">Nossos serviços. Usamos todos os dados em nosso poder para nos ajudar a operar, executar, aprimorar, entender, personalizar, dar suporte e anunciar nossos Serviços.</p>
				
				<p className="default-sub-description text-justify">Proteção e segurança. A Three Pixels preza muito pela privacidade de seus usuários. Nenhum dado coletado pela aplicação é usado pela empresa. Não usamos nenhum tipo de armazenamento em banco de dados para dados coletados durante o uso da aplicação.</p>
				
				<p className="default-sub-description text-justify">Transferência de dados. Cientes que estamos tratando dados pessoais, usamos a transmissão de dados por meio de uma criptografia moderna. Todas requisições são feitas via HTTPS (Hyper Text Transfer Protocol Secure - protocolo de transferência de hipertexto seguro).</p>
				
				<p className="default-sub-description text-justify">Sem banners de anúncios. Não autorizamos banners de anúncios de terceiros. Nós não temos a intenção de mudar isto, mas caso venhamos a fazer, nós iremos atualizar esta política.</p>			
				
				<h2 className="default-sub-title half">Atualizações em nossa política</h2>
				<div className="default-sub-title-separator" />
				
				<p className="default-sub-description text-justify">Podemos alterar ou atualizar nossa Política de Privacidade. Notificaremos sobre alterações feitas nesta Política de Privacidade, conforme a necessidade, e atualizaremos a data de última modificação na parte inferior desta Política de Privacidade. A continuação do uso de nossos Serviços ratifica sua aceitação de nossa Política de Privacidade e alterações posteriores.</p>
				
				<h2 className="default-sub-title half">Fale conosco</h2>
				<div className="default-sub-title-separator" />
				
				<p className="default-sub-description text-justify">Em caso de dúvidas sobre esta Política de Privacidade, entre em contato conosco.</p>
				
				<p className="default-sub-description text-justify">(49) 2049.4353 | contato@threepixels.com.br</p>
				
				<p className="default-sub-description text-justify">Última modificação: 30/04/2021</p>
				
				<p className="default-sub-description text-justify">Atual: <a href="/politica-de-privacidade">Política de privacidade</a></p>				
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default PrivacyPolicy;
