import React, { useState } from "react";
import {
  Col,
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Row,
} from "react-bootstrap";

import Auth from "../../auth";

import "./index.css";

import Logo from "../logo";

function Header({ page: pageIni }) {
  const [page] = useState(pageIni);

  const CheckPageDropdown = () => {
    if (page === "/nossos-servicos" || page === "/consultas" || page === "/retirada-de-senhas") {
      return true;
    }
    return false;
  };

  const CheckPagePrivacyDropdown = () => {
    if (page === "/politica-de-cookies" || page === "/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc" || page === "/politica-de-privacidade") {
      return true;
    }
    return false;
  };

  const handleLogout = async (event) => {
    await Auth.signOut({ token: Auth.isAuth().token });
    window.location = "";
  };

  return (
    <header className="header">
      <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
          <Container>
            <Navbar.Brand href="/">
              <Logo className="logo light" inline="true" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link className={page === "/" ? "active" : ""} href="/">Início</Nav.Link>
                <Nav.Link className={page === "/sobre" ? "active" : ""} href="/sobre">Sobre</Nav.Link>
                <Nav.Link className={page.startsWith("/noticias") ? "active" : ""} href="/noticias/1">Notícias</Nav.Link>
                <NavDropdown className={CheckPageDropdown() === true ? "active" : ""} title="Serviços" id="dropdown-services" alignRight>
                  <NavDropdown.Item className={page === "/nossos-servicos" ? "active" : ""} href="/nossos-servicos">Nossos serviços</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/escritura-e-procuracao-online" ? "active" : ""} href="/escritura-e-procuracao-online">Escritura e procuração online</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/consultas" ? "active" : ""} href="/consultas">Consultas</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/retirada-de-senhas" ? "active" : ""} href="/retirada-de-senhas">Retirada de senhas</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/tabela-de-emolumentos" ? "active" : ""} href="/tabela-de-emolumentos">Tabela de emolumentos</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className={page === "/contato" ? "active" : ""} href="/contato">Contato</Nav.Link>
                {/* <NavDropdown className={CheckPagePrivacyDropdown() === true ? "active" : ""} title="Privacidade" id="dropdown-privacy" alignRight>
                  <NavDropdown.Item className={page === "/politica-de-cookies" ? "active" : ""} href="/politica-de-cookies">Política de Cookies</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc" ? "active" : ""} href="/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc">Política de Privacidade</NavDropdown.Item>
                  <NavDropdown.Item className={page === "/politica-de-privacidade" ? "active" : ""} href="/politica-de-privacidade">Política de Privacidade do Aplicativo</NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
          {
            Auth.isAuth() !== false ? (
              <div className="header-navbar-user">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <ul className="navbar-user-nav">
                        <li>{ Auth.isAuth().name.substr(0, 12) } { Auth.isAuth().name.length > 12 ? "..." : "" } (<span onClick={handleLogout}>Sair</span>)</li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </div>
            ) : (
              <>
              </>
            )
          }
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
