import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";

function CardNoticeComponent({ description: descIni = false, header: headerIni, icon: iconIni = false }) {
  const [description] = useState(descIni);
  const [header] = useState(headerIni);
  const [icon] = useState(iconIni);

  const CheckIcon = () => {
    if (icon) {
      return (
        <div>
          <FontAwesomeIcon className="card-service-icon" icon={icon} />
          <span className="card-service-header-title">{header}</span>
        </div>
      );
    }
    return header;
  };

  return (
    <Card className="card-service" bg="light">
      <Card.Header><CheckIcon /></Card.Header>
      {
        description !== false ? (
          <Card.Body>
            <Card.Text>{description}</Card.Text>
          </Card.Body>
        ) : ""
      }
    </Card>
  );
}

export default CardNoticeComponent;
