import React from "react";

import Api from "./services/api";

class SatisfactionSurvey extends React.Component {
  async create({ data }) {
    const response = await Api.post("/satisfaction-survey", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }
}

export default new SatisfactionSurvey();
