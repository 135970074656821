import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import ContactForm from "../../services/forms/contact";

import BannerContact from "../../assets/banners/contact.jpg";

function Contact() {
  const [title] = useState("Contato - Tabelionato Camboriú");
  const [page] = useState("/contato");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerContact}) no-repeat`} description="Deixe sua dúvida, sugestão ou reclamação." title="Contato" />
        <section className="section-default section-contact">
          <Container>
            <Row className="justify-content-md-center">
              <Col className="section-contact-form" xs={12} md={8}>
                <ContactForm />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="section-contact-details" xs={12}>
                <div className="section-content">
                  <div className="section-content-center">
                    <ul>
                      <li className="section-contact-details-phone">47 3365 4336 | 47 3365 5177 | 47 99172 7671 | 47 99172 4336</li>
                      <li className="section-contact-details-email">contato@tabelionatocamboriu.com.br</li>
                      <li className="section-contact-details-address">Rua Anastácio José Pereira, 87, Centro, 88340-236 - Camboriú - SC</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Contact;
