import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerOurServices from "../../assets/banners/our-services.jpg";

function PrivacyPolicy(props) {
  const [title] = useState("Política de Privacidade - Tabelionato Camboriú");
  const [page] = useState("/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerOurServices}) no-repeat`} title="Política de privacidade" />
        <section className="section-default section-our-services">
          <Container>
            <Row>
              <Col className="privacy-policy-content-box" xs={12}>
                <h2 className="default-sub-title half">Tabelionato de Notas e Protesto de Títulos e Documentos de Camboriú</h2>
                <div className="default-sub-title-separator" />
                <h2 className="default-sub-title half">Política de Privacidade</h2>
                <div className="default-sub-title-separator" />
			  </Col>
			  <Col className="privacy-policy-content-box" xs={12} lg={6}>
                <div id="indice">
                  <h2 className="default-sub-title half text-justify">Índice</h2>
                  <p className="default-sub-description text-justify">1. <a href="#introducao">Introdução</a></p>
                  <p className="default-sub-description text-justify">2. <a href="#secao-de-clientes">Seção de clientes</a></p>
                  <p className="default-sub-description text-justify">2.1. <a href="#tratamento-de-dados-em-atos-extraprotocolares">Tratamento de dados em atos extraprotocolares</a></p>
                  <p className="default-sub-description text-justify">2.2. <a href="#tratamento-de-dados-em-atos-protocolares">Tratamento de dados em atos protocolares</a></p>
                  <p className="default-sub-description text-justify">2.3. <a href="#tratamento-de-dados-em-protestos-de-titulos">Tratamento de dados em protestos de títulos</a></p>
                  <p className="default-sub-description text-justify">3 <a href="#secao-de-colaboradores-e-candidatos">Seção de colaboradores e candidatos</a></p>
                  <p className="default-sub-description text-justify">3.1. <a href="#secao-de-colaboradores-e-candidatos">Tratamento de dados de colaboradores</a></p>
                  <p className="default-sub-description text-justify">3.2. <a href="#secao-de-candidatos">Tratamento de dados de candidatos à vaga de trabalho</a></p>
                  <p className="default-sub-description text-justify">4 <a href="#secao-de-fornecedores-e-terceirizados">Seção de fornecedores e terceirizados</a></p>
                  <p className="default-sub-description text-justify">5 <a href="#sobre-as-obrigacoes-dos-titulares">Sobre as obrigações dos titulares</a></p>
                  <p className="default-sub-description text-justify">6 <a href="#sobre-seguranca-e-protecao-de-dados-pessoais">Sobre segurança e proteção de dados pessoais</a></p>
                  <p className="default-sub-description text-justify">7 <a href="#sobre-alteracoes-nesta-politica">Sobre alterações nesta Política</a></p>
                  <p className="default-sub-description text-justify">8 <a href="#nossos-dados">Nossos dados</a></p>
                  <p className="default-sub-description text-justify">9 <a href="#nosso-dpo">Nosso Encarregado pelo Tratamento de Dados Pessoais (DPO)</a></p>
                  <p className="default-sub-description text-justify">10 <a href="#saber-mais">Quero saber mais</a></p>
                </div>
			  </Col>
			  <Col className="privacy-policy-content-box" xs={12} lg={6}>
			  	<iframe style={{ width: "100%", height: "715px" }} src="/formulario.html" />
			  </Col>
			  <Col className="privacy-policy-content-box" xs={12}>
                <div id="introducao">
                  <h2 className="default-sub-title half text-justify">Introdução</h2>
                  <p className="default-sub-description text-justify">Esta Política de Privacidade (ou simplesmente “Política”), tem por objetivo esclarecer e tornar público o uso dos dados pessoais no Tabelionato de Notas e Protesto de Títulos e Documentos de Camboriú, em linguagem clara e acessível.</p>
                  <p className="default-sub-description text-justify">Para começar, vamos falar um pouco sobre o que é um cartório, também conhecido como serventia extrajudicial.</p>
                  <p className="default-sub-description text-justify">Os cartórios prestam à sociedade serviços notariais e de registro para garantir a publicidade, autenticidade, segurança e eficácia dos atos jurídicos. Eles formalizam e conservam diversos atos importantes da vida em sociedade, tais como: nascimentos, casamentos e óbitos; escrituras, procurações, testamentos, divórcios e inventários; autenticações de cópias e reconhecimento de firmas; registros de imóveis; notificações e registro de documentos e de pessoas jurídicas; protestos de títulos e documentos de dívida, dentre outros.</p>
                  <p className="default-sub-description text-justify">Desta forma, os cartórios têm trabalhados com dados pessoais em benefício da sociedade desde 1565, quando foi inaugurado o primeiro cartório no Brasil, e segue rígidos padrões para garantir a privacidade e a proteção dos dados pessoais a eles confiados.</p>
                  <p className="default-sub-description text-justify">Para facilitar a sua busca de informações, nos informe o tipo de relacionamento que você tem conosco:</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li><a href="#secao-de-clientes">Sou cliente</a></li>
                      <li><a href="#secao-de-colaboradores-e-candidatos">Sou colaborador ou candidato a uma vaga de trabalho</a></li>
                      <li><a href="#secao-de-fornecedores-e-terceirizados">Sou fornecedor ou terceirizado</a></li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-clientes">
                  <h2 className="default-sub-title half text-justify">2 SEÇÃO DE CLIENTES</h2>
                  <p className="default-sub-description text-justify">Estamos felizes com sua visita em nossa Política e em seu interesse em saber mais sobre como tratamos seus dados pessoais. Que tal nos informa para qual serviço você deseja mais informações? Para isso basta clicar na relação abaixo.</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li><a href="#tratamento-de-dados-em-atos-extraprotocolares">Apostila de Haia</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-protocolares">Atas notariais</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-extraprotocolares">Autenticação</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-extraprotocolares">Comunicação de venda de veículos</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-extraprotocolares">Emissão de certidões</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-protocolares">Escritura</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-protocolares">Procuração</a></li>
                      <li><a href="#tratamento-de-dados-em-protestos-de-titulos">Protesto de título</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-extraprotocolares">Reconhecimento de firma</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-protocolares">Substabelecimento</a></li>
                      <li><a href="#tratamento-de-dados-em-atos-protocolares">Testamento</a></li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares">
                  <h2 className="default-sub-title half text-justify">2.1 TRATAMENTO DE DADOS EM ATOS EXTRAPROTOCOLARES</h2>
                  <p className="default-sub-description text-justify">São atos sem o seu arquivamento no livro de notas, ou seja, o ato é avulso e entregue para o solicitante que lhe dá destino. Desta forma, não há a possibilidade da emissão de certidões. Em nosso cartório prestamos os seguintes serviços desta forma:</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Apostila de Haia</li>
                      <li>Autenticação</li>
                      <li>Comunicação de venda de veículos</li>
                      <li>Emissão de certidões</li>
                      <li>Reconhecimento de firma</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">2.1.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Dados de seu documento de identificação apresentado, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone residencial, comercial e/ou celular, endereço residencial e endereço eletrônico (e-mail).</li>
                      <li>Dados de pagamentos dos serviços solicitados.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-hipotese">
                  <h2 className="default-sub-title half text-justify">2.1.2 A hipótese da LGPD (base legal) nos permite tratar esses dados é o cumprimento de obrigação legal ou regulatória (LGPD, art. 7, II). Seguem abaixo as leis e provimentos que devemos observar:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>CÓDIGO DE NORMAS DA CORREGEDORIA-GERAL DA JUSTIÇA DO ESTADO DE SANTA CATARINA;</li>
                      <li>DECRETO No 93.240, DE 9 DE SETEMBRO DE 1986 (Regulamenta a Lei no 7.433, de 18 de dezembro de 1985, que “dispõe sobre os requisitos para a lavratura de escrituras públicas e dá outras providências”);</li>
                      <li>LEI COMPLEMENTAR ESTADUAL No 705, DE 21 DE SETEMBRO DE 2017 (Dispõe sobre a comunicação, por parte dos tabelionatos de notas, ao Departamento Estadual de Trânsito [DETRAN], nos casos de transferência de propriedade de veículos automotores);</li>
                      <li>LEI COMPLEMENTAR ESTADUAL No 755, DE 26 DE DEZEMBRO DE 2019 (Dispõe sobre os emolumentos no Estado de Santa Catarina e adota outras providências);</li>
                      <li>LEI No 6.015, DE 31 DE DEZEMBRO DE 1973 (Dispõe sobre os registros públicos, e dá outras providências);</li>
                      <li>LEI No 7.433, DE 18 DE DEZEMBRO DE 1985 (Dispõe sobre os requisitos para a lavratura de escrituras públicas e dá outras providências);</li>
                      <li>LEI No 8.935, DE 18 DE NOVEMBRO DE 1994 (Lei dos Cartórios);</li>
                      <li>LEI No 10.406, DE 10 DE JANEIRO DE 2002 (Código Civil Brasileiro);</li>
                      <li>LEI No 13.105, DE 16 DE MARÇO DE 2015 (Código de Processo Civil Brasileiro);</li>
                      <li>PROVIMENTO CNJ No 18, DE 12 DE NOVEMBRO DE 20212 (Dispõe sobre a instituição e funcionamento da Central Nacional de Serviços Eletrônicos Compartilhados – CENSEC);</li>
                      <li>PROVIMENTO CNJ No 45, DE 13 DE MAIO DE 2015 (Revoga o Provimento 34 de 09/07/2013 e Orientação 6 de 25/11/2013 e consolida normas relativas à manutenção e escrituração dos livros Diário Auxiliar, Visitas e Correições e Controle de Depósito Prévio pelos titulares de delegações e responsáveis interinos do serviço extrajudicial de notas e registros públicos, e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015 (Dispõe sobre a conservação de documentos nos cartórios extrajudiciais);</li>
                      <li>PROVIMENTO No 61, DE 17 DE OUTUBRO DE 2017 (Dispõe sobre a obrigatoriedade de informação do número do Cadastro de Pessoa Física (CPF), do Cadastro Nacional de Pessoa Jurídica (CNPJ) e dos dados necessários à completa qualificação das partes nos feitos distribuídos ao Poder Judiciário e aos serviços extrajudiciais em todo o território nacional);</li>
                      <li>PROVIMENTO CNJ No 62, DE 14 DE NOVEMBRO DE 2017 (Dispõe sobre a uniformização dos procedimentos para a aposição de apostila, no âmbito do Poder Judiciário, da Convenção sobre a Eliminação da Exigência de Legalização de Documentos Públicos Estrangeiros, celebrada na Haia, em 5 de outubro de 1961 [Convenção da Apostila]);</li>
                      <li>PROVIMENTO CNJ No 88, DE 1o DE OUTUBRO DE 2019 (Dispõe sobre a política, os procedimentos e os controles a serem adotados pelos notários e registradores visando à prevenção dos crimes de lavagem de dinheiro, previstos na Lei n. 9.613, de 3 de março de 1998, e do financiamento do terrorismo, previsto na Lei n. 13.260, de 16 de março de 2016, e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 100, DE 26 DE MAIO DE 2020 (Dispõe sobre a prática de atos notariais eletrônicos utilizando o sistema e-Notariado, cria a Matrícula Notarial Eletrônica-MNE e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 103, DE 04 DE JUNHO DE 2020 (Dispõe sobre a Autorização Eletrônica de Viagem nacional e internacional de crianças e adolescentes de até 16 (dezesseis) anos desacompanhados de ambos ou um de seus pais e dá outras providências).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-tempo">
                  <h2 className="default-sub-title half text-justify">2.1.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Cada tipo de serviço tem um prazo de guarda regulamentado de acordo com ao Tabela de Temporalidade de Documentos do PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015, que pode ser consultado clicando aqui.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-compartilhamento">
                  <h2 className="default-sub-title half text-justify">2.1.4 Com quem compartilhamos seus dados para esses tipos de serviços:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Empresa Extradigital, que fornece o sistema de escrituração dos atos doTabelionato;</li>
                      <li>Empresa Infostore, que fornece o sistema de backup;</li>
                      <li>Tribunal de Justiça de Santa Catarina, por meio do selo de fiscalização digital;</li>
                      <li>Colégio Notarial do Brasil - Conselho Federal e Seção Santa Catarina, por meio do Cadastro Único de Clientes do Notariado e da plataforma de atos eletrônicos e-Notariado;</li>
                      <li>Departamento de Trânsito de Santa Catarina (DETRAN-SC) e Colégio Notarial do Brasil - Seção Santa Catarina, para a realização da comunicação de venda de veículos automotores através da CentralDUT;</li>
                      <li>Conselho Nacional da Justiça e Associação Nacional dos Notários e Registradores - ANOREG/BR, para a realização do apostilamento de documentos por meio do sistema CENSEC; e</li>
                      <li>Direção do Foro da Comarca de Camboriú, quando da apresentação anual, para visto, do livro de registro auxiliar das receitas e das despesas do Tabelionato;</li>
                      <li>Corregedor-Geral do Foro Extrajudicial do Estado de Santa Catarina, Juiz Corregedor Permanente e seus auxiliares, nas inspeções e correições do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-direitos">
                  <h2 className="default-sub-title half text-justify">2.1.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados* (LGPD, art. 18, III);</li>
                    </ul>
                  </p>
                  <p className="default-sub-description text-justify">* O livre acesso é restrito ao titular dos dados pessoais e poderá ser promovido mediante informação verbal ou escrita, conforme for solicitado.</p>
                  <p className="default-sub-description text-justify">As certidões e informações sobre o conteúdo dos atos notariais e de registro, para efeito de publicidade e de vigência, serão fornecidas mediante remuneração por emolumentos, ressalvadas as hipóteses de gratuidade previstas em lei específica.</p>
                  <p className="default-sub-description text-justify">Para a expedição de certidão ou informação pessoal restrita poderá ser exigido o fornecimento, por escrito, da identificação do solicitante e da finalidade da solicitação – caso de testamento.</p>
                  <p className="default-sub-description text-justify">A retificação de dado pessoal constante em registro de protesto e/ou em ato notarial deverá observar o procedimento, extrajudicial ou judicial, previsto na legislação ou em norma específica.</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-extraprotocolares-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">2.1.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares">
                  <h2 className="default-sub-title half text-justify">2.2 TRATAMENTO DE DADOS EM ATOS PROTOCOLARES</h2>
                  <p className="default-sub-description text-justify">São atos com arquivamento no livro de notas, ou seja, há a possibilidade da emissão de certidões. Em nosso cartório prestamos os seguintes serviços desta forma:</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Atas notariais;</li>
                      <li>Escritura;</li>
                      <li>Procuração;</li>
                      <li>Substabelecimento;</li>
                      <li>Testamento.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">2.2.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Dados de seu documento de identificação apresentado, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone residencial, comercial e/ou celular e endereço eletrônico (e-mail);</li>
                      <li>Dados de pagamentos dos serviços solicitados.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-hipotese">
                  <h2 className="default-sub-title half text-justify">2.2.2 A hipótese da LGPD (base legal) nos permite tratar esses dados é o cumprimento de obrigação legal ou regulatória (LGPD, art. 7, II). Seguem abaixo as leis e provimentos que devemos observar:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>CÓDIGO DE NORMAS DA CORREGEDORIA-GERAL DA JUSTIÇA DO ESTADO DE SANTA CATARINA;</li>
                      <li>DECRETO No 93.240, DE 9 DE SETEMBRO DE 1986 (Regulamenta a Lei no 7.433, de 18 de dezembro de 1985, que “dispõe sobre os requisitos para a lavratura de escrituras públicas e dá outras providências”);</li>
                      <li>INSTRUÇÃO NORMATIVA RFB No 1112, DE 28 DE DEZEMBRO DE 2010 (Aprova o programa e as instruções de preenchimento da Declaração sobre Operações Imobiliárias, versão 6.1, define regras para a sua apresentação e dá outras providências);</li>
                      <li>LEI No 6.015, DE 31 DE DEZEMBRO DE 1973 (Dispõe sobre os registros públicos, e dá outras providências);</li>
                      <li>LEI No 7.433, DE 18 DE DEZEMBRO DE 1985 (Dispõe sobre os requisitos para a lavratura de escrituras públicas e dá outras providências);</li>
                      <li>LEI No 8.935, DE 18 DE NOVEMBRO DE 1994 (Lei dos Cartórios);</li>
                      <li>LEI No 10.406, DE 10 DE JANEIRO DE 2002 (Código Civil Brasileiro);</li>
                      <li>LEI No 10.426, DE 24 DE ABRIL DE 2002 - ART. 8o (Altera a legislação tributária federal e dá outras providências);</li>
                      <li>LEI No 13.105, DE 16 DE MARÇO DE 2015 (Código de Processo Civil Brasileiro);</li>
                      <li>PROVIMENTO CNJ No 18, DE 28 DE AGOSTO DE 2012 (Dispõe sobre a instituição e funcionamento da Central Notarial de Serviços Eletrônicos Compartilhados - CENSEC);</li>
                      <li>RESOLUÇÃO CNJ No 35, DE 24 DE ABRIL DE 2007 (Disciplina a lavratura dos atos notariais relacionados a inventário, partilha, separação consensual, divórcio consensual e extinção consensual de união estável por via administrativa);</li>
                      <li>PORTARIA SPU/ME No 24.218, DE 26 DE NOVEMBRO DE 2020 (Estabelece as normas para envio da Declaração sobre Operações Imobiliárias em Terrenos da União (Doitu) pelos cartórios à Secretaria de Coordenação e Governança do Patrimônio da União, da Secretaria Especial de Desestatização, Desinvestimento e Mercados, do Ministério da Economia);</li>
                      <li>PROVIMENTO CNJ No 39, DE 25 DE JULHO DE 2015 (Dispõe sobre a instituição e funcionamento da Central Nacional de Indisponibilidade de Bens - CNIB, destinada a recepcionar comunicações de indisponibilidade de bens imóveis não individualizados);</li>
                      <li>PROVIMENTO CNJ No 45, DE 13 DE MAIO DE 2015 (Revoga o Provimento 34 de 09/07/2013 e Orientação 6 de 25/11/2013 e consolida normas relativas à manutenção e escrituração dos livros Diário Auxiliar, Visitas e Correições e Controle de Depósito Prévio pelos titulares de delegações e responsáveis interinos do serviço extrajudicial de notas e registros públicos, e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015 (Dispõe sobre a conservação de documentos nos cartórios extrajudiciais);</li>
                      <li>PROVIMENTO CNJ No 61, DE 17 DE OUTUBRO DE 2017 (Dispõe sobre a obrigatoriedade de informação do número do Cadastro de Pessoa Física (CPF), do Cadastro Nacional de Pessoa Jurídica (CNPJ) e dos dados necessários à completa qualificação das partes nos feitos distribuídos ao Poder Judiciário e aos serviços extrajudiciais em todo o território nacional);</li>
                      <li>PROVIMENTO CNJ No 62, DE 14 DE NOVEMBRO DE 2017 (Dispõe sobre a uniformização dos procedimentos para a aposição de apostila, no âmbito do Poder Judiciário, da Convenção sobre a Eliminação da Exigência de Legalização de Documentos Públicos Estrangeiros, celebrada na Haia, em 5 de outubro de 1961 [Convenção da Apostila]);</li>
                      <li>PROVIMENTO CNJ No 65, DE 14 DE DEZEMBRO DE 2017 (Estabelece diretrizes para o procedimento da usucapião extrajudicial nos serviços notariais e de registro de imóveis);</li>
                      <li>PROVIMENTO CNJ No 88, DE 1o DE OUTUBRO DE 2019 (Dispõe sobre a política, os procedimentos e os controles a serem adotados pelos notários e registradores visando à prevenção dos crimes de lavagem de dinheiro, previstos na Lei n. 9.613, de 3 de março de 1998, e do financiamento do terrorismo, previsto na Lei n. 13.260, de 16 de março de 2016, e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 100, DE 26 DE MAIO DE 2020 (Dispõe sobre a prática de atos notariais eletrônicos utilizando o sistema e-Notariado, cria a Matrícula Notarial Eletrônica-MNE e dá outras providências).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-tempo">
                  <h2 className="default-sub-title half text-justify">2.2.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Cada tipo de serviço tem um prazo de guarda regulamentado de acordo com ao Tabela de Temporalidade de Documentos do PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015, que pode ser consultado clicando aqui.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-compartilhamento">
                  <h2 className="default-sub-title half text-justify">2.2.4 Com quem compartilhamos seus dados para esses tipos de serviços:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Compartilhamento público via emissão de certidão a qualquer interessado, de acordo com o Art. 17 da LEI No 6.015, DE 31 DE DEZEMBRO DE 1973;</li>
                      <li>Empresa Extradigital que fornece os sistemas de escrituração dos atos do Tabelionato;</li>
                      <li>Empresa Infostore, que fornece o sistema de backup;</li>
                      <li>Tribunal de Justiça de Santa Catarina, por meio do selo de fiscalização digital</li>
                      <li>Central Nacional de Indisponibilidade de Bens (CNIB), para consulta de ordens judiciais de indisponibilidade de bens imóveis não individualizados;</li>
                      <li>Central Notarial de Serviços Eletrônicos Compartilhados (CENSEC) para prestar informações sobre testamentos, procurações e escrituras públicas de qualquer natureza;</li>
                      <li>Colégio Notarial do Brasil – Seção Santa Catarina (CNB-SC) para prestar informações sobre testamentos, procurações e escrituras públicas de qualquer natureza;</li>
                      <li>Conselho de Controle de Atividades Financeiras (COAF) para reportar atividades financeiras suspeitas;</li>
                      <li>Receita Federal Receita por meio de Declaração de Operações Imobiliárias – DOI;</li>
                      <li>Microsoft, para uso do ambiente de nuvem do Azure para armazenamento de dados pessoais em documentos digitais.</li>
                      <li>Direção do Foro da Comarca de Camboriú, quando da apresentação anual, para visto, do livro de registro auxiliar das receitas e das despesas do Tabelionato;</li>
                      <li>Corregedor-Geral do Foro Extrajudicial do Estado de Santa Catarina, Juiz Corregedor Permanente e seus auxiliares, nas inspeções e correições do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-direitos">
                  <h2 className="default-sub-title half text-justify">2.2.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados* (LGPD, art. 18, III);</li>
                    </ul>
                  </p>
                  <p className="default-sub-description text-justify">* O livre acesso é restrito ao titular dos dados pessoais e poderá ser promovido mediante informação verbal ou escrita, conforme for solicitado.</p>
                  <p className="default-sub-description text-justify">As certidões e informações sobre o conteúdo dos atos notariais e de registro, para efeito de publicidade e de vigência, serão fornecidas mediante remuneração por emolumentos, ressalvadas as hipóteses de gratuidade previstas em lei específica.</p>
                  <p className="default-sub-description text-justify">Para a expedição de certidão ou informação pessoal restrita poderá ser exigido o fornecimento, por escrito, da identificação do solicitante e da finalidade da solicitação – caso de testamento.</p>
                  <p className="default-sub-description text-justify">A retificação de dado pessoal constante em registro de protesto e/ou em ato notarial deverá observar o procedimento, extrajudicial ou judicial, previsto na legislação ou em norma específica.</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-atos-protocolares-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">2.2.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos">
                  <h2 className="default-sub-title half text-justify">2.3 TRATAMENTO DE DADOS EM PROTESTOS DE TÍTULOS</h2>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">2.3.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Dados de seu documento de identificação apresentado, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone residencial, comercial e/ou celular e endereço eletrônico (e-mail);</li>
                      <li>Dados de pagamentos dos serviços solicitados.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-hipotese">
                  <h2 className="default-sub-title half text-justify">2.3.2 A hipótese da LGPD (base legal) nos permite tratar esses dados é o cumprimento de obrigação legal ou regulatória (LGPD, art. 7, II). Seguem abaixo as leis e provimentos que devemos observar:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>CÓDIGO DE NORMAS DA CORREGEDORIA-GERAL DA JUSTIÇA DO ESTADO DE SANTA CATARINA;</li>
                      <li>LEI No 6.015, DE 31 DE DEZEMBRO DE 1973 (Dispõe sobre os registros públicos, e dá outras providências);</li>
                      <li>LEI No 8.935, DE 18 DE NOVEMBRO DE 1994 (Lei dos Cartórios);</li>
                      <li>LEI No 9.492, DE 10 DE SETEMBRO DE 1997 (Define competência, regulamenta os serviços concernentes ao protesto de títulos e outros documentos de dívida e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 45, DE 13 DE MAIO DE 2015 (Revoga o Provimento 34 de 09/07/2013 e Orientação 6 de 25/11/2013 e consolida normas relativas à manutenção e escrituração dos livros Diário Auxiliar, Visitas e Correições e Controle de Depósito Prévio pelos titulares de delegações e responsáveis interinos do serviço extrajudicial de notas e registros públicos, e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015 (Dispõe sobre a conservação de documentos nos cartórios extrajudiciais);</li>
                      <li>PROVIMENTO CNJ No 61 DE 17/10/2017 (Dispõe sobre a obrigatoriedade de informação do número do Cadastro de Pessoa Física (CPF), do Cadastro Nacional de Pessoa Jurídica (CNPJ) e dos dados necessários à completa qualificação das partes nos feitos distribuídos ao Poder Judiciário e aos serviços extrajudiciais em todo o território nacional);</li>
                      <li>PROVIMENTO CNJ No 87, DE 11 DE SETEMBRO DE 2019 (Dispõe sobre normas gerais de procedimentos para o protesto extrajudicial de títulos e outros documentos de dívida, regulamenta a implantação da Central Nacional de Serviços Eletrônicos dos Tabeliães de Protestos de Títulos - CENPROT e dá outras providências);</li>
                      <li>PROVIMENTO CNJ No 88, DE 1o DE OUTUBRO DE 2019 (Dispõe sobre a política, os procedimentos e os controles a serem adotados pelos notários e registradores visando à prevenção dos crimes de lavagem de dinheiro, previstos na Lei n. 9.613, de 3 de março de 1998, e do financiamento do terrorismo, previsto na Lei n. 13.260, de 16 de março de 2016, e dá outras providências).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-tempo">
                  <h2 className="default-sub-title half text-justify">2.3.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Cada tipo de serviço tem um prazo de guarda regulamentado de acordo com ao Tabela de Temporalidade de Documentos do PROVIMENTO CNJ No 50, DE 28 DE SETEMBRO DE 2015, que pode ser consultado clicando aqui.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-compartilhamento">
                  <h2 className="default-sub-title half text-justify">2.3.4 Com quem compartilhamos seus dados para esses tipos de serviços:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Compartilhamento reservado com entidades representativas da indústria e do comércio ou vinculadas à proteção do crédito por meio de certidão diária em forma de relação, nos termos do art. 29 da LEI No 9.492, DE 10 DE SETEMBRO DE 1997.</li>
                      <li>Empresa Extradigital, que fornece o sistema de escrituração dos atos do Tabelionato;</li>
                      <li>Empresa Infostore, que fornece o sistema de backup;</li>
                      <li>Tribunal de Justiça de Santa Catarina, por meio do selo de fiscalização digital;</li>
                      <li>Central de Remessas de Arquivos - CRA e Central de Distribuição de Títiulos, relativamente à distribuição dos títulos entre os tabelionatos de protesto da comarca na forma do art. 7o da LEI No 9.492, DE 10 DE SETEMBRO DE 1997;</li>
                      <li>Central Nacional de Serviços Eletrônicos dos Tabeliães de Protestos de Títulos - CENPROT, para recepção de arquivos relativos a títulos apresentados, retirados e cancelados e emissão de certidões;</li>
                      <li>Conselho de Controle de Atividades Financeiras (COAF) para reportar atividades financeiras suspeitas;</li>
                      <li>Direção do Foro da Comarca de Camboriú, quando da apresentação anual, para visto, do livro de registro auxiliar das receitas e das despesas do Tabelionato;</li>
                      <li>Corregedor-Geral do Foro Extrajudicial do Estado de Santa Catarina, Juiz Corregedor Permanente e seus auxiliares, nas inspeções e correições do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-direitos">
                  <h2 className="default-sub-title half text-justify">2.3.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados* (LGPD, art. 18, III);</li>
                    </ul>
                  </p>
                  <p className="default-sub-description text-justify">* O livre acesso é restrito ao titular dos dados pessoais e poderá ser promovido mediante informação verbal ou escrita, conforme for solicitado.</p>
                  <p className="default-sub-description text-justify">As certidões e informações sobre o conteúdo dos atos notariais e de registro, para efeito de publicidade e de vigência, serão fornecidas mediante remuneração por emolumentos, ressalvadas as hipóteses de gratuidade previstas em lei específica.</p>
                  <p className="default-sub-description text-justify">Para a expedição de certidão ou informação pessoal restrita poderá ser exigido o fornecimento, por escrito, da identificação do solicitante e da finalidade da solicitação – caso de testamento.</p>
                  <p className="default-sub-description text-justify">A retificação de dado pessoal constante em registro de protesto e/ou em ato notarial deverá observar o procedimento, extrajudicial ou judicial, previsto na legislação ou em norma específica.</p>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII).</li>
                    </ul>
                  </p>
                </div>

                <div id="tratamento-de-dados-em-protestos-de-titulos-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">2.3.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos">
                  <h2 className="default-sub-title half text-justify">3 SEÇÃO DE COLABORADORES E CANDIDATOS</h2>
                  <h2 className="default-sub-title half text-justify">3.1 TRATAMENTO DE DADOS DE COLABORADORES</h2>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">3.1.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Matrícula;</li>
                      <li>Dados de seu documento de identificação, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone residencial e/ou celular, endereço residencial e endereço eletrônico (e-mail);</li>
                      <li>Título de eleitor;</li>
                      <li>Carteira de trabalho;</li>
                      <li>Número do PIS;</li>
                      <li>Dado biométrico;</li>
                      <li>Estado Civil;</li>
                      <li>Exame médico;</li>
                      <li>Atestado médico;</li>
                      <li>Controle de presença;</li>
                      <li>Formação acadêmica;</li>
                      <li>Escolaridade;</li>
                      <li>Cargo;</li>
                      <li>Renda;</li>
                      <li>Conta corrente;</li>
                      <li>Transações bancárias;</li>
                      <li>Nome do cônjuge;</li>
                      <li>Dados de identificação do cônjuge, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, filiação;</li>
                      <li>Dados de identificação dos filhos e/ou dependentes legais, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, filiação.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-hipotese">
                  <h2 className="default-sub-title half text-justify">3.1.2 As hipóteses da LGPD (bases legais) nos permitem tratar esses dados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Cumprimento de obrigação legal ou regulatória (LGPD, art. 7, II). As leis e provimentos que devemos observar são:
                        <ul className="default-ul-description">
                          <li>DECRETO-LEI No 5.452, DE 1o DE MAIO DE 1943 (CLT);</li>
                          <li>PORTARIA No 1.195, DE 30 DE OUTUBRO DE 2019 (Disciplina o registro de empregados e a anotação na Carteira de Trabalho e Previdência Social em meio eletrônico, e dá outras providências);</li>
                          <li>LEI No 4.923, DE 23 DE DEZEMBRO DE 1965 (Institui o Cadastro Permanente das Admissões e Dispensas de Empregados);</li>
                          <li>PORTARIA No 1.127, DE 14 DE OUTUBRO DE 2019 (Define as datas e condições em que as obrigações de prestação de informações pelo empregador nos sistemas CAGED e RAIS serão substituídas pelo Sistema de Escrituração Digital das Obrigações Fiscais, Previdenciárias e Trabalhistas - eSocial).</li>
                        </ul>
                      </li>
                      <li>Convenção coletiva do SINTRACESC - Sindicato dos Trabalhadores em Cartórios do Estado de Santa Catarina.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-tempo">
                  <h2 className="default-sub-title half text-justify">3.1.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Cada tipo de documento relativo à contratação tem um tempo de guarda diferente. De modo geral as leis exigem:
                        <ul className="default-ul-description">
                          <li>Documentos de desligamento da empresa: 2 anos;</li>
                          <li>CAGED (Cadastro Geral de Empregados e Desempregados): 3 anos;</li>
                          <li>Documentos de folha de pagamento e sistemas: 10 anos;</li>
                          <li>Programa de Controle Médico de Saúde Ocupacional (PCMSO): 20 anos;</li>
                          <li>Perfil Profissiográfico Previdenciário (PPP): 20 anos;</li>
                          <li>Documentos trabalhistas e previdenciários: 30 anos;</li>
                          <li>Contrato de trabalho e livro ou ficha de registro do funcionário: indeterminado;</li>
                          <li>Demais documentos: 5 anos.</li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-compartilhamento">
                  <h2 className="default-sub-title half text-justify">3.1.4 Com quem compartilhamos seus dados pessoais:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Com a Contabilidade Luz para fechamento da folha de pagamento e demais obrigações trabalhistas e previdenciárias;</li>
                      <li>Com o SINTRACESC - Sindicato dos Trabalhadores em Cartórios do Estado de Santa Catarina;</li>
                      <li>Ministério do Trabalho e Previdência para cumprimento das obrigações de prestação de informações pelo empregador no Sistema de Escrituração Digital das Obrigações Fiscais, Previdenciárias e Trabalhistas – eSocial;</li>
                      <li>Caixa Econômica Federal para troca de informações relacionadas ao FGTS com o uso do canal eletrônico Conectividade Social;</li>
                      <li>Microsoft para utilização das ferramentas de colaboração e envio e recebimento de e-mails;</li>
                      <li>Microsoft para uso do ambiente de nuvem da Azure para armazenamento de dados pessoais em documentos digitais;</li>
                      <li>Com Sodexo para oferecer cartão de alimentação e/ou refeição ao colaborador.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-direitos">
                  <h2 className="default-sub-title half text-justify">3.1.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados (LGPD, art. 18, III);</li>
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII);</li>
                      <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa (LGPD, art. 18, VIII) em caso de benefícios não obrigatórios;</li>
                      <li>Revogação do consentimento (art. 18, IX) em caso de benefícios não obrigatórios;</li>
                      <li>Anonimização, bloqueio ou eliminação de dados (art. 18, IV) em caso de benefícios não obrigatórios;</li>
                      <li>Eliminação dos dados pessoais tratados com o consentimento do titular (art. 18, VI) em caso de benefícios não obrigatórios.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-colaboradores-e-candidatos-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">3.1.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Fale diretamente com o responsável pelos recursos humanos de sua serventia, ou</li>
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos">
                  <h2 className="default-sub-title half text-justify">3.2 TRATAMENTO DE DADOS DE CANDIDATOS À VAGA DE TRABALHO</h2>
                </div>

                <div id="secao-de-candidatos-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">3.2.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Dados de seu documento de identificação, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone residencial e/ou celular, endereço residencial e endereço eletrônico (e-mail);</li>
                      <li>Formação acadêmica;</li>
                      <li>Formação acadêmica;</li>
                      <li>Dados de empregos anteriores.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos-hipotese">
                  <h2 className="default-sub-title half text-justify">3.2.2 As hipóteses da LGPD (bases legais) nos permitem tratar esses dados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (LGPD, art. 7, V).</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos-tempo">
                  <h2 className="default-sub-title half text-justify">3.2.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Os dados são eliminados ao final de cada processo seletivo, ou seja, assim que a vaga é preenchida.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos-compartilhamento">
                  <h2 className="default-sub-title half text-justify">3.2.4 Com quem compartilhamos seus dados pessoais:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Microsoft para utilização das ferramentas de colaboração e envio e recebimento de e-mails.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos-direitos">
                  <h2 className="default-sub-title half text-justify">3.2.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados (LGPD, art. 18, III);</li>
                      <li>Anonimização, bloqueio ou eliminação de dados (art. 18, IV);</li>
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII).</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-candidatos-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">3.2.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Fale diretamente com o responsável pelos recursos humanos de sua serventia, ou</li>
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados">
                  <h2 className="default-sub-title half text-justify">4 SEÇÃO DE FORNECEDORES E TERCEIRIZADOS</h2>
                  <h2 className="default-sub-title half text-justify">4.1 TRATAMENTO DE DADOS DE FORNECEDORES</h2>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">4.1.1 Dados pessoais que coletamos:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Dados de seu documento de identificação, que podem incluir: nome, foto do documento, CPF, RG, CNH, naturalidade, nacionalidade, data de nascimento, sexo, nome de seu pai e de sua mãe;</li>
                      <li>Dados de contato, que podem incluir: números de telefone comercial, residencial e/ou celular, endereço comercial e endereço eletrônico (e-mail);</li>
                      <li>Dados bancários;</li>
                      <li>Dados de transações bancárias (pagamentos).</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-hipotese">
                  <h2 className="default-sub-title half text-justify">4.1.2 As hipóteses da LGPD (bases legais) nos permitem tratar esses dados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Execução ou preparação contratual (art. 7, V). As leis e provimentos que devemos observar são:
                        <ul className="default-ul-description">
                          <li>LEI No 10.406, DE 10 DE JANEIRO DE 2002 (Institui o Código Civil.)</li>
                        </ul>
                      </li>
                      <li>Exercício regular de direitos (art. 7, VI).
                        <ul className="default-ul-description">
                          <li>LEI No 10.406, DE 10 DE JANEIRO DE 2002 (Institui o Código Civil.)</li>
                          <li>EMBARGOS DE DIVERGÊNCIA EM RESP. No 1.281.594 - SP (2011/0211890-7).</li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-tempo">
                  <h2 className="default-sub-title half text-justify">4.1.3 Quanto tempo meu dado fica guardado no cartório?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Os dados referentes a contratos com o Tabelionato são armazenados por 10 (dez) anos para permitir o exercício regular de direitos.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-compartilhamento">
                  <h2 className="default-sub-title half text-justify">3.1.4 Com quem compartilhamos seus dados pessoais:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Com a Contabilidade Luz para gestão fiscal e contábil do Tabelionato.</li>
                      <li>Empresa Infostore, que fornece o sistema de armazenamento de dados.</li>
                      <li>Extradigital que fornece os sistemas para otimizar e gerenciar as rotinas do Tabelionato;</li>
                      <li>ITware Soluções em TI, que fornece o serviço de adequação à LGPD deste tabelionato.</li>
                      <li>Microsoft para utilização das ferramentas de colaboração e envio e recebimento de e-mails;</li>
                      <li>Microsoft para uso do ambiente de nuvem da Azure para armazenamento de dados pessoais em documentos digitais.</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-direitos">
                  <h2 className="default-sub-title half text-justify">4.1.5 Seus direitos como titular dos dados pessoais tratados são:</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Confirmação da existência de tratamento (LGPD, art. 18, I);</li>
                      <li>Acesso aos dados (LGPD, art. 18, II);</li>
                      <li>Correção de dados incompletos, inexatos ou desatualizados (LGPD, art. 18, III);</li>
                      <li>Informação sobre compartilhamento de dados (LGPD art. 18, VII);</li>
                    </ul>
                  </p>
                </div>

                <div id="secao-de-fornecedores-e-terceirizados-exercer-direitos">
                  <h2 className="default-sub-title half text-justify">4.1.6 Como faço para exercer meus direitos relacionados acima?</h2>
                  <p className="default-sub-description text-justify">
                    <ul className="default-ul-description">
                      <li>Fale diretamente com o tabelião responsável pelo seu contrato ou</li>
                      <li>Clique <strong><a href="https://www.itware.com.br/falecomdpo/" target="_blank">aqui</a></strong> e faça uma solicitação direta ao Encarregado pelo tratamento de dados pessoais do Tabelionato.</li>
                    </ul>
                  </p>
                </div>

                <div id="sobre-as-obrigacoes-dos-titulares">
                  <h2 className="default-sub-title half text-justify">5 Sobre as obrigações dos titulares</h2>
                  <p className="default-sub-description text-justify">5.1 Avaliar esta Política de Privacidade;</p>
                  <p className="default-sub-description text-justify">5.2 Fornecer dados precisos e verdadeiros;</p>
                  <p className="default-sub-description text-justify">5.3 Informar sobre alterações de seus dados por intermédio de nossos canais de comunicação;</p>
                  <p className="default-sub-description text-justify">5.4 Não fornecer dados de terceiros;</p>
                  <p className="default-sub-description text-justify">5.5 Não tornar público dado pessoal informado em nosso Tabelionato, salvo em caso de livre e espontânea vontade, tendo ciência que dados publicizados podem ser tratados sem o Consentimento do titular (LGPD, Art. 7o, I) do titular;</p>
                  <p className="default-sub-description text-justify">5.6 Responder por dados falsos, excessivos ou imprecisos que vier a fornecer, e pelos danos, diretos ou indiretos, que isso ao Tabelionato, a si mesmo ou a terceiros.</p>
                </div>

                <div id="sobre-seguranca-e-protecao-de-dados-pessoais">
                  <h2 className="default-sub-title half text-justify">6 Sobre segurança e proteção de dados pessoais</h2>
                  <p className="default-sub-description text-justify">6.1 O Tabelionato armazena dados pessoais em repositórios protegidos e realiza cópias de salvaguarda periodicamente, garantindo a disponibilidade dos dados;</p>
                  <p className="default-sub-description text-justify">6.2 Todo o tráfego online de dados pessoais é realizado por canais criptografados;</p>
                  <p className="default-sub-description text-justify">6.3 Todos os dispositivos que usamos no acesso de dados pessoais são protegidos por softwares de segurança que garantem a confidencialidade e integridade dos dados;</p>
                  <p className="default-sub-description text-justify">6.4 Todos os dados pessoais em meios físicos (papeis) são armazenados em locais controlados (trancados e com controle de acesso), garantindo que apenas pessoal autorizado tenha acesso a eles;</p>
                  <p className="default-sub-description text-justify">6.5 Os operadores escolhidos para o processamento são empresas com políticas de segurança e salvaguarda, garantindo a proteção dos dados pessoais;</p>
                </div>

                <div id="sobre-alteracoes-nesta-politica">
                  <h2 className="default-sub-title half text-justify">7 Sobre alterações nesta política</h2>
                  <p className="default-sub-description text-justify">7.1 Podemos atualizar esta política sempre que necessário, mantendo-a neste link.</p>
                  <p className="default-sub-description text-justify">7.2 As alterações não serão comunicadas ao titular, portanto, você deve verificar esta Política periodicamente ou sempre que tiver dúvidas.</p>
                </div>

                <div id="nossos-dados">
                  <h2 className="default-sub-title half text-justify">8 Nossos dados:</h2>
                  <p className="default-sub-description text-justify">Tabelionato de Notas e Protesto de Títulos e Documentos de Camboriú</p>
                  <p className="default-sub-description text-justify">Rua Anastácio José Pereira, no 87</p>
                  <p className="default-sub-description text-justify">Centro - Camboriú / SC</p>
                  <p className="default-sub-description text-justify">CEP 88.340-236</p>
                  <p className="default-sub-description text-justify">Horário: segunda à sexta das 8:00 às 18:00</p>
                  <p className="default-sub-description text-justify">E-mail: <a href="mailto:tabelionatocamboriu@hotmail.com">tabelionatocamboriu@hotmail.com</a></p>
                </div>

                <div id="nosso-dpo">
                  <h2 className="default-sub-title half text-justify">9 Nosso Encarregado pelo Tratamento de Dados Pessoais (DPO)</h2>
                  <p className="default-sub-description text-justify">Nome: Marcos Alessandro Siqueira</p>
                  <p className="default-sub-description text-justify">Contato: clique aqui</p>
                </div>

                <div id="saber-mais">
                  <h2 className="default-sub-title half text-justify">10 Quero saber mais</h2>
                  <p className="default-sub-description text-justify">Para maiores informações favor entrar em contato pelo canal Fale com o Encarregado (DPO).</p>
                </div>

                <div id="versao">
                  <h2 className="default-sub-title half text-justify">Dados desta versão:</h2>
                  <p className="default-sub-description text-justify">Versão 1, revisão 1, publicada em 30 de dezembro de 2021.</p>
                  <p className="default-sub-description text-justify">Elaborada pela equipe de privacidade da ITware Soluções em TI para o Tabelionato de Notas e Protesto de Títulos e Documentos de Camboriú em Santa Catarina.</p>
                  <p className="default-sub-description text-justify">Revisada pelo Encarregado pelo Tratamento de Dados Pessoais do Tabelionato, Marcos Siqueira, em 30 de dezembro de 2021.</p>
                  <p className="default-sub-description text-justify">Revisada pela Tabeliã Titular, Dra Simone Viegas da Cunha Cesar, em 01/01/2022</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default PrivacyPolicy;
