import React, { useState } from "react";
import {
  Card, Container, Col, Nav, Row, Tab, Tabs,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import CertificateForm from "../../services/forms/certificate";
import ProcurationForm from "../../services/forms/procuration";

import BannerQueries from "../../assets/banners/queries.jpg";

function Queries() {
  const [title] = useState("Consultas - Tabelionato Camboriú");
  const [page] = useState("/consultas");
  const [tab, setTab] = useState("#item-tab-0");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerQueries}) no-repeat`} description="Solicite uma segunda via de certidão ou confirmação da vigência e eficácia de uma procuração." title="Consultas" />
        <section className="section-default section-queries">
          <Container>
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#item-tab-0" onSelect={k => setTab(k)} activeKey={tab}>
                      <Nav.Item>
                        <Nav.Link href={"#item-tab-0"}>Pesquisa gratuita de protestos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href={"#item-tab-1"}>Certidão</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href={"#item-tab-2"}>Procuração</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <Tabs activeKey={tab}>
                      <Tab eventKey={"#item-tab-0"}>
                        <Container className="zerar-padding">
                          <Row>
                            <Col xs={12}>
                              <p className="default-sub-description description-ip"><strong><a href="http://www.ieptb.com.br/" title="Instituto de Protestos" target="_blank" rel="noopener noreferrer">Acesse o site</a></strong> do IEPTB para efetuar uma busca de protestos em todo o Brasil.</p>
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                      <Tab eventKey={"#item-tab-1"}>
                        <Container className="zerar-padding">
                          <Row>
                            <Col xs={12}>
                              <CertificateForm />
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                      <Tab eventKey={"#item-tab-2"}>
                        <Container className="zerar-padding">
                          <Row>
                            <Col xs={12}>
                              <ProcurationForm />
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Queries;
