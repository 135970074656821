import React from "react";

import IconMarker from "../../assets/icon-marker.png";

import "./index.css";

import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = "pk.eyJ1IjoidGhyZWVwaXhlbHMiLCJhIjoiY2p6eWUxcmwxMWF0ZzNtcGZtZnEwODZpcyJ9.fMM6L7ZQ-P13MP35gKIDdw";

class Map extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      lng: props.lng,
      lat: props.lat,
      zoom: props.zoom,
    };
    this.container = {
      className: props.className,
      id: props.id,
    };
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;
    const { id } = this.container;

    const map = new mapboxgl.Map({
      container: id,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [lng, lat],
      zoom,
      attributionControl: false,
      zoomControl: false,
    });

    map.scrollZoom.disable();

    map.on("move", () => {
      const { lng, lat } = map.getCenter();
      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      });
    });

    var el = document.createElement("div");
    el.className = "marker";
    el.style.background = `url(${IconMarker})`;

    new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .setPopup(new mapboxgl.Popup({offset:[0, -30]})
        .setHTML("<p>Rua Anastácio José Pereira, 87, Centro.<br>47 3365 4336 / 47 3365 5177</p>"))
      .addTo(map);
  }

  render() {
    const { id, className } = this.container;
    return (
      <div id={id} className={className} />
    );
  }
}

export default Map;
