import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faGooglePlus,
} from "@fortawesome/free-brands-svg-icons";

import {
  faHandPointRight,
  faTimes,
  faPencilAlt,
  faCertificate,
  faHome,
  faIndustry,
  faFileAlt,
  faUsers,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

import "./App.css";

import Routes from "./routes";

library.add(
  faFacebookF,
  faTwitter,
  faGooglePlus,
  faHandPointRight,
  faTimes,
  faPencilAlt,
  faCertificate,
  faHome,
  faIndustry,
  faFileAlt,
  faUsers,
  faComment,
);

function App() {
  return (
    <Routes />
  );
}

export default App;
