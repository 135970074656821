import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Form, Spinner } from "react-bootstrap";

import "./index.css";

import Auth from "../../../auth";
import Error from "../../../error";

function Signup() {
  const [validated, setValidated] = useState(false);
  const [code, setCode] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(!loading) {
      setAlertType("danger");
      setAlertMessage("");
      setLoading(true);

      if(form.checkValidity() === false) {
        setAlertMessage("Preencha os campos obrigatórios.");
      }

      setValidated(true);

      const token = Auth.isAuth() ? Auth.isAuth().token : false;
      const response = await Auth.confirmSms({ code, token });

      if(response.success === false) {
        const message = Error.getType({ type: response.error.details[0].message });
        if(message) {
          setAlertMessage(message.value);
        }
      }
      else {
        window.location = "/retirada-de-senhas";
      }

      setLoading(false);
    }
  };

  const handleButton = async () => {
    if(!loading) {
      setAlertType("danger");
      setAlertMessage("");
      setLoading(true);

      const token = Auth.isAuth() ? Auth.isAuth().token : false;
      const response = await Auth.resendSms({ token });

      if(response !== false) {
        if(response.success === false) {
          const message = Error.getType({ type: response.error.details[0].message });
          if(message) {
            setAlertMessage(message.value);
          }
        }
        else {
          setAlertType("success");
          setAlertMessage("O código SMS foi enviado com sucesso.");
        }
      }
      else {
        setAlertType("danger");
        setAlertMessage("O código SMS foi enviado recentemente, aguarde antes de enviar outro.");
      }

      setLoading(false);
    }
  };

  const checkConfirmed = () => {
    const confirmed = Auth.isAuth() ? Auth.isAuth().sms.confirmed : true;
    if(confirmed) {
      window.location = "/retirada-de-senhas";
    }
  };

  useEffect(() => {
    checkConfirmed();
  }, []);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Código:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Código"
            maxLength="4"
            onChange={e => setCode(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com o código recebido por SMS, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          {
            alertMessage === "" ? "" : (
              <Alert variant={alertType}>{alertMessage}</Alert>
            )
          }
        </Form.Group>
      </Form.Row>
      <div className="text-right">
        <Button className="btn-reenviar" variant="dark" type="button" disabled={loading ? true : false} onClick={handleButton}>
          {
            loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <></>
            )
          }
          { loading ? " Aguarde..." : " Reenviar SMS" }
        </Button>
        <Button variant="dark" type="submit" disabled={loading ? true : false}>
          {
            loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <></>
            )
          }
          { loading ? " Aguarde..." : " Confirmar SMS" }
        </Button>
      </div>
    </Form>
  );
}

export default Signup;
