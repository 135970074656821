import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import ConfirmSmsForm from "../../services/forms/confirmSms";

import BannerAuthenticate from "../../assets/banners/authenticate.jpg";

function ConfirmSms() {
  const [title] = useState("Confirmar SMS - Tabelionato Camboriú");
  const [page] = useState("/confirmar-sms");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerAuthenticate}) no-repeat`} description="Confirme o código SMS para validar o seu acesso." title="Confirmar SMS" />
        <section className="section-default section-confirm-sms">
          <Container>
            <Row className="justify-content-md-center">
              <Col className="section-confirm-sms-form" xs={12} md={8}>
                <ConfirmSmsForm />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default ConfirmSms;
