import React from "react";
import Moment from "moment";

import Api from "./services/api";

class Notice extends React.Component {
  async find({ page }) {
    const response = await Api.get(`/notice/${page}`).then(response => {
      return response.data;
    }).catch(error => {
      return false;
    });
    return response;
  }

  async findOne({ id }) {
    const response = await Api.get(`/notice/id/${id}`).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }

  getContentPrev({ content }) {
    const sub = content.replace(/<[^>]+>/g, "");
    if(sub.length > 100) {
      return sub.substr(0, 97) + "...";
    }
    return sub;
  }

  getDate({ date }) {
    return Moment(date).format("DD/MM/YYYY");
  }
}

export default new Notice();
