import React, { useEffect, useState }  from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import "./index.css";

import Logo from "../logo";
import Map from "../map";

function Footer({ page }) {
  const [cookies, setCookies] = useState(true);
  const [whatsapp, setWhatsapp] = useState(false);
  const [phone] = useState("5547991727671");
  const [message, setMessage] = useState("");

  // const cookiePopup = () => {
  //   if(localStorage.getItem("accept_cookie_new") === null) {
  //     localStorage.setItem("accept_cookie_new", JSON.stringify({
  //       accept: false,
  //       data: (new Date()).getTime()
  //     }));
  //   }

  //   if(!JSON.parse(localStorage.getItem("accept_cookie_new")).accept) {
  //     setCookies(true);
  //   }
  // };

  const acceptCookiePopup = () => {
    // localStorage.setItem("accept_cookie_new", JSON.stringify({
    //   accept: true,
    //   data: (new Date()).getTime()
    // }));
    setCookies(false);
  };

  const handleWhatsapp = () => {
    setWhatsapp(!whatsapp);
  };

  // useEffect(() => {
  //   cookiePopup();
  // }, []);

  return (
    <footer className="footer">
      <Container className="footer-dsc">
        <Row className="justify-content-center">
          <Col className="text-center text-sm-right col-md order-md-3 footer-logo" xs={12} sm={12} md={3}>
            <Logo className="logo dark" inline="false" />
          </Col>
          <Col className="text-left col-auto footer-menu" xs={12} md={3}>
            <h5>Mapa do site</h5>
            <ul className="list-unstyled ml-3">
              <li><a className={page === "/" ? "active" : ""} href="/">Início</a></li>
              <li><a className={page === "/sobre" ? "active" : ""} href="/sobre">Sobre</a></li>
              <li><a className={page.startsWith("/noticias") ? "active" : ""} href="/noticias/1">Notícias</a></li>
              <li><a className={page === "/contato" ? "active" : ""} href="/contato">Contato</a></li>
            </ul>
          </Col>
          <Col className="text-left col-auto footer-menu" xs={12} md={3}>
            <h5>Serviços</h5>
            <ul className="list-unstyled ml-3">
              <li><a className={page === "/nossos-servicos" ? "active" : ""} href="/nossos-servicos">Nossos serviços</a></li>
              <li><a className={page === "/escritura-e-procuracao-online" ? "active" : ""} href="/escritura-e-procuracao-online">Escritura e procuração online</a></li>
              <li><a className={page === "/consultas" ? "active" : ""} href="/consultas">Consultas</a></li>
              <li><a className={page === "/retirada-de-senhas" ? "active" : ""} href="/retirada-de-senhas">Retirada de senhas</a></li>
              <li><a className={page === "/tabela-de-emolumentos" ? "active" : ""} href="/tabela-de-emolumentos">Tabela de emolumentos</a></li>
            </ul>
          </Col>
          <Col className="text-left col-auto footer-menu" xs={12} md={3}>
            <h5>Privacidade</h5>
            <ul className="list-unstyled ml-3">
              <li><a className={page === "/politica-de-cookies" ? "active" : ""} href="/politica-de-cookies">Política de Cookies</a></li>
              <li><a className={page === "/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc" ? "active" : ""} href="/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc">Política de Privacidade</a></li>
              <li><a className={page === "/politica-de-privacidade" ? "active" : ""} href="/politica-de-privacidade">Política de Privacidade do Aplicativo</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center footer-address">
            <p className="text-center">RUA ANASTÁCIO JOSÉ PEREIRA, 87, CENTRO, 88340-236 - CAMBORIÚ - SC</p>
            <p className="text-center"><strong>Tabelionato Camboriú © 2019</strong></p>
          </Col>
        </Row>
      </Container>
      <Map className="footer-map" id="map" lat="-27.024451" lng="-48.655119" zoom="16" />
      <div className="footer-credits">
        <p>
          Site desenvolvido por&nbsp;
          <a href="https://www.threepixels.com.br" target="_blank" rel="noopener noreferrer">Three Pixels</a>
          .
        </p>
      </div>

      <div className={`fixed-bottom ${cookies ? "fixed-bottom-cookies" : ""}`}>
        <div id="whatsapp" className={`whatsapp ${whatsapp ? "" : ""}`}>
          <div className={`menu-whatsapp ${whatsapp ? "open" : ""}`}>
            <div className="menu-whatsapp-header">
              <Button variant="light" type="button" onClick={handleWhatsapp}>
                <i className="fas fa-times" />
              </Button>
              Estamos aqui para responder às suas perguntas. Tire suas dúvidas!
            </div>
            <div className="menu-whatsapp-body">
              <Form.Control
                required
                type="text"
                placeholder="Eu queria saber...."
                maxLength="255"
                onChange={e => setMessage(e.target.value)}
              />
              <Button variant="light" as="a" href={`https://web.whatsapp.com/send?phone=${phone}&text=${message}`} disabled={message === ""} target="_blank" rel="noopener noreferrer">
                <i className="fas fa-paper-plane" />
              </Button>
            </div>
          </div>
          <div className="button-whatsapp">
            <Button variant="light" type="button" onClick={handleWhatsapp}>
              <i className="fab fa-whatsapp" />
              Olá, tudo bem? Podemos ajudar?
            </Button>
          </div>
        </div>
        <div id="cookies" className={`cookies ${cookies ? "" : "hide"}`}>
          <nav className="nav">
            <ul>
              <li className="nav-margin-bottom">Nós utilizamos cookies que possibilitam a navegação em nossas aplicações coletando informações anônimas sobre as páginas acessadas
              e cookies que memorizam suas preferências e escolhas. Dessa maneira sua presença continuada em nosso site será entendida como consentimento.</li>
              <li><a href="/politica-de-cookies">Política de Cookies</a></li>
              <li><a href="/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc">Política de Privacidade (Atualizado: 01/07/2022)</a></li>
              <li><a href="/politica-de-privacidade">Política de Privacidade do Aplicativo (Atualizado: 07/11/2021)</a></li>
            </ul>
          </nav>
          <nav className="nav">
            <ul>
              <li className="text-align-right">
                <Button variant="dark" type="button" onClick={acceptCookiePopup}>Aceitar</Button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
