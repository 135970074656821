import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";

import "./index.css";

function CardNoticeComponent({ button: buttonIni = true, date: dateIni = false, description: descIni, img: imgIni = false, title: titleIni, url: urlIni }) {
  const [button] = useState(buttonIni);
  const [date] = useState(dateIni);
  const [description] = useState(descIni);
  const [img] = useState(imgIni);
  const [title] = useState(titleIni);
  const [url] = useState(urlIni);

  const CheckButton = () => {
    if (button) {
      return (
        <Button className="card-notice-link" as="a" variant="dark" href={url}>Ler mais...</Button>
      );
    }
    return false;
  };

  const CheckDate = () => {
    if (date) {
      return (
        <p className="card-notice-date">{date}</p>
      );
    }
    return false;
  };

  const CheckImg = () => {
    if (img) {
      return (
        <Card.Img variant="top" src={img} />
      );
    }
    return false;
  };

  function handleClick() {
    window.location = url;
  }

  return (
    <Card className="card-notice" onClick={handleClick}>
      <CheckImg />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <CheckButton />
        <CheckDate />
      </Card.Body>
    </Card>
  );
}

export default CardNoticeComponent;
