import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

import "./index.css";

function PaginationComponent(props) {
  const [paginate] = useState(props.paginate);
  const [url] = useState(props.url);

  const CheckRenderPrev = () => {
    if((paginate.page - 1) >= 1) {
      return (
        <Pagination.Prev href={`${url}/${(paginate.page - 1)}`} />
      );
    }
    return (
      <Pagination.Prev disabled />
    );
  }

  const RenderPrev = () => {
    let prev = [];
    for (var i = 2; i > 0; i--) {
      if((paginate.page - i) >= 1) {
        prev.push(<Pagination.Item href={`${url}/${paginate.page - i}`} key={paginate.page - i}>{paginate.page - i}</Pagination.Item>);
      }
    }
    return prev;
  };

  const CheckRenderNext = () => {
    if((paginate.page + 1) <= paginate.pages) {
      return (
        <Pagination.Next href={`${url}/${(paginate.page + 1)}`} />
      );
    }
    return (
      <Pagination.Next disabled />
    );
  }

  const RenderNext = () => {
    let next = [];
    for (var i = 1; i < 3; i++) {
      if((paginate.page + i) <= paginate.pages) {
        next.push(<Pagination.Item href={`${url}/${paginate.page + i}`} key={paginate.page + i}>{paginate.page + i}</Pagination.Item>);
      }
    }
    return next;
  };

  const CheckRenderLast = () => {
    if(paginate.pages > 1) {
      return (
        <Pagination.Last href={`${url}/${paginate.pages}`} />
      );
    }
    return (
      <Pagination.Last disabled />
    );
  }

  return (
    <Pagination className="pagination-component justify-content-center">
      <Pagination.First href={`${url}/1`} />
      <CheckRenderPrev />
      <RenderPrev />
      <Pagination.Item key={paginate.page} active>{paginate.page}</Pagination.Item>
      <RenderNext />
      <CheckRenderNext />
      <CheckRenderLast />
    </Pagination>
  );
}

export default PaginationComponent;
