import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerAbout from "../../assets/banners/about.jpg";

function About() {
  const [title] = useState("Sobre - Tabelionato Camboriú");
  const [page] = useState("/sobre");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerAbout}) no-repeat`} description="Saiba um pouco mais sobre nós." title="Sobre" />
        <section className="section-default section-about">
          <Container>
            <Row>
              <Col>
                <h4 className="default-sub-title">Simone Viegas da Cunha Cesar</h4>
                <div className="default-sub-title-separator" />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs={12} md={8}>
                <p className="default-sub-description">
                  Natural de Florianópolis, Bacharel em Direito pela Universidade do Vale do Itajaí – UNIVALI, Estado de Santa Catarina; Especialista em Direito Notarial e Registral pela
                  Faculdade Arthur Thomas – FAAT; Exerceu a advocacia na área cível e imobiliária, em 2002 foi nomeada Escrevente Notarial do 1º Tabelionato de Notas e Protestos de Títulos do Município e Comarca de
                  Itajaí/SC., atividade que exerceu até 2004, quando foi aprovada em concurso público realizado pelo Tribunal de Justiça do Estado de Santa Catarina, recebendo a Delegação do Tabelionato de Notas e
                  Protesto de Títulos do Município e Comarca de São José do Cedro/SC. Atualmente, por meio de concurso público, é titular do Tabelionato de Notas e Protestos de Títulos do Município e Comarca de
                  Camboriú/SC, atividade que exerce com orgulho, profissionalismo, ética e dedicação.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default About;
