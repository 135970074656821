import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import "./index.css";

function ShareButtonComponent({ title: titleIni, url: urlIni }) {
  const [title] = useState(titleIni);
  const [url] = useState(urlIni);
  
  return (
    <div className="share-button-component">
      <ul className="share-button-ul">
        <li className="share-button">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>Compartilhar no Facebook</Tooltip>
            )}
          >
            <button className="share-fb" type="button">
              <div className="section-content">
                <div className="section-content-center">
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                  <FacebookShareButton className="share-button-click" url={url} />
                </div>
              </div>
            </button>
          </OverlayTrigger>
        </li>
        <li className="share-button">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="tooltip-top">Compartilhar no Twitter</Tooltip>
            )}
          >
            <button className="share-twitter" type="button">
              <div className="section-content">
                <div className="section-content-center">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                  <TwitterShareButton className="share-button-click" title={title} url={url} />
                </div>
              </div>
            </button>
          </OverlayTrigger>
        </li>
      </ul>
    </div>
  );
}

export default ShareButtonComponent;
