import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Form, Spinner } from "react-bootstrap";

import "./index.css";

import Auth from "../../../auth";
import Error from "../../../error";

function Signup() {
  const [validated, setValidated] = useState(false);
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(!loading) {
      setAlertType("danger");
      setAlertMessage("");
      setLoading(true);

      if(form.checkValidity() === false) {
        setAlertMessage("Preencha os campos obrigatórios.");
      }
      else if(password !== passwordConfirm) {
        setValidated(false);
        setAlertMessage("A senha e a confirmação deve senha devem ser idênticas.");
      }

      setValidated(true);

      const response = await Auth.signUp({
        data: {
          account: {
            login: cellphone,
            password: password
          },
          cellphone: cellphone,
          email: email,
          name: name
        }
      });

      if(response.success === false) {
        const message = Error.getType({ type: response.error.details[0].message });
        if(message) {
          setAlertMessage(message.value);
        }
      }
      else {
        if(response.data.sms.confirmed) {
          window.location = "/retirada-de-senhas";
        }
        else {
          window.location = "/confirmar-sms";
        }
      }

      setLoading(false);
    }
  };

  const checkLogin = () => {
    const auth = Auth.isAuth() ? true : false;
    const confirmed = Auth.isAuth() ? Auth.isAuth().sms.confirmed : false;
    if(auth) {
      if(confirmed) {
        window.location = "/retirada-de-senhas";
      }
      else {
        window.location = "/confirmar-sms";
      }
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Nome:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nome"
            maxLength="50"
            onChange={e => setName(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com o seu nome, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>E-mail:</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="E-mail"
            maxLength="50"
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com um email válido, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Celular:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Celular"
            maxLength="11"
            onChange={e => setCellphone(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com o seu celular, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Senha:</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Senha"
            onChange={e => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com a sua senha, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Confirmar senha:</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Confirmar senha"
            onChange={e => setPasswordConfirm(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com a confirmação da sua senha, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          {
            alertMessage === "" ? "" : (
              <Alert variant={alertType}>{alertMessage}</Alert>
            )
          }
        </Form.Group>
      </Form.Row>
      <div className="text-right">
        <Button variant="dark" type="submit" disabled={loading ? true : false}>
          {
            loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <></>
            )
          }
          { loading ? " Aguarde..." : " Enviar" }
        </Button>
      </div>
    </Form>
  );
}

export default Signup;
