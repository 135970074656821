import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import ContactOurServicesOnlineForm from "../../services/forms/contactOurServicesOnline";

import BannerQueries from "../../assets/banners/queries.jpg";

function OurServicesOnline() {
  const [title] = useState("Escritura e procuração online - Tabelionato Camboriú");
  const [page] = useState("/escritura-e-procuracao-online");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerQueries}) no-repeat`} description="Agora é possível fazer escrituras e procurações públicas online." title="Escritura e procuração online" />
        <section className="section-default section-our-services section-our-services-page">
          <Container>
            <Row>
              <Col>
                <p className="default-sub-description text-justify">O provimento 100/2020 do Conselho  Nacional de justiça dispõe sobre a prática de atos notariais eletrônicos, possibilitando a lavratura de escrituras e procurações públicas,por tabelionato de Notas, de forma on-line,  sem a necessidade de dirigir-se ao Cartório, proporcionando agilidade e praticidade com a mesma segurança jurídica.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">1. Contato <i className="far fa-envelope" /></h2>
                <div className="default-sub-title-separator half w-100" />
                <p className="default-sub-description text-justify">Entre em contato por telefone, e-mail ou WhatsApp com nossos atendentes de acordo com o tipo de ato que pretende realizar. Ainda, você pode entrar em contato por meio do <a href="#form-our-services-online">formulário</a> ao fim desta página.</p>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="default-sub-title-separator-mg" />
                    <h2 className="default-sub-title half text-justify">Escritura:</h2>
                    <div className="default-sub-title-separator half w-100" />
                    <p className="default-sub-description text-justify">Telefone: (47) 3365 4336</p>
                    <p className="default-sub-description text-justify">WhatsApp: (47) 9 9172 4336</p>
                    <p className="default-sub-description text-justify">E-mail: <a href="mailto:tabelionatocamboriu@hotmail.com">tabelionatocamboriu@hotmail.com</a></p>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="default-sub-title-separator-mg" />
                    <h2 className="default-sub-title half text-justify">Procuração:</h2>
                    <div className="default-sub-title-separator half w-100" />
                    <p className="default-sub-description text-justify">Telefone: (47) 3365 5177</p>
                    <p className="default-sub-description text-justify">WhatsApp: (47) 9 9172 7671</p>
                    <p className="default-sub-description text-justify">E-mail: <a href="mailto:tabelionatocamboriu@hotmail.com">tabelionatocamboriu@hotmail.com</a></p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">2. Lista de documentos <i className="fas fa-tasks" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Você receberá por e-mail as informações e a lista dos documentos necessários. Encaminhe os documentos e informações por e-mail ou WhatsApp.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">3. Impostos <i className="fas fa-receipt" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Você receberá os boletos para pagamento dos impostos e taxas, por-email.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">4. Agendamento <i className="fas fa-history" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Você receberá a minuta para conferência e agendamento para a lavratura definitiva do ato através de assinatura digital e videoconferência do ato.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">5. Assinatura <i className="fas fa-signature" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">A tabeliã ou seus prepostos realizarão com você a videoconferência e a assinatura digital por via do certificado e-Notariado.</p>
                <p className="default-sub-description text-justify">e-Notariado = Caso você ainda não possua o certificado e-Notariado, poderá realiza-lo de forma gratuita na sede do Tabelionato.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">6. Pagamento <i className="far fa-credit-card" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Realize o pagamento dos emolumentos por Pix, TED, cartão de débito ou diretamente no cartório.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">7. Recebimento <i className="fas fa-file-contract" /></h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Receba o traslado de seu documento em casa ou retire diretamente no Tabelionato.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">E-Notariado</h2>
                <div className="default-sub-title-separator half w-100" />

                <p className="default-sub-description text-justify">Faça seu certificado no e-notariado, de forma gratuita, no Tabelionato de Camboriú, ou caso não esteja em Camboriú, dirija-se a um Tabelionato credenciado. Lista no site &nbsp;
                  <a href="www.e-notariado.org.br" target="_blank">www.e-notariado.org.br</a></p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="default-sub-title-separator-mg" />
                <h2 className="default-sub-title half text-justify">Formulário para contato</h2>
                <div className="default-sub-title-separator half w-100" />
                <ContactOurServicesOnlineForm />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default OurServicesOnline;
