import React, { useState } from "react";
import {
  Card, Container, Col, Nav, ResponsiveEmbed, Row, Tab, Tabs,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerQueries from "../../assets/banners/queries.jpg";
import EmolumentsTablePdf from "../../assets/pdf/tabela-de-emolumentos.pdf";

function EmolumentsTable() {
  const [title] = useState("Tabela de emolumentos - Tabelionato Camboriú");
  const [page] = useState("/tabela-de-emolumentos");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerQueries}) no-repeat`} description="" title="Tabela de emolumentos" />
        <section className="section-default section-queries">
          <Container>
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="#item-tab-0" activeKey="#item-tab-0">
                      <Nav.Item>
                        <Nav.Link href={"#item-tab-0"}>Tabela de emolumentos</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <Tabs activeKey="#item-tab-0">
                      <Tab eventKey="#item-tab-0">
                        <Container className="zerar-padding">
                          <Row>
                            <Col xs={12}>
                              <div style={{ width: "100%", height: "auto" }}>
                                <ResponsiveEmbed>
                                  <iframe src={EmolumentsTablePdf} title="Tabela de emolumentos"></iframe>
                                </ResponsiveEmbed>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default EmolumentsTable;
