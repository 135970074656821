import React, { useState } from "react";
import {
  Col, Container, Row,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import SatisfactionSurveyForm from "../../services/forms/satisfactionSurvey";

import BannerSatisfactionSurvey from "../../assets/banners/satisfaction-survey.jpg";

function SatisfactionSurvey() {
  const [title] = useState("Pesquisa de Satisfação - Tabelionato Camboriú");
  const [page] = useState("/pesquisa-de-satisfacao");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerSatisfactionSurvey}) no-repeat`} description="Deixe o seu feedback para podermos melhorar sempre!" title="Pesquisa de Satisfação" />
        <section className="section-default section-satisfaction-survey">
          <Container>
            <Row>
              <Col xs={12}>
                <SatisfactionSurveyForm />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default SatisfactionSurvey;
