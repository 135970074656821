import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";

import "./index.css";

import Auth from "../../auth";
import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import AuthenticateForm from "../../services/forms/authenticate";

import BannerAuthenticate from "../../assets/banners/authenticate.jpg";

function Authenticate() {
  const [title] = useState("Autenticar - Tabelionato Camboriú");
  const [page] = useState("/autenticar");

  const handleOnload = () => {
    document.title = title;
  };

  const responseFacebook = async (fb) => {
    const response = await Auth.signInFb({ access_token: window.FB.getAccessToken() });
    if(response.success) {
      window.location = "/retirada-de-senhas";
    }
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerAuthenticate}) no-repeat`} description="Área restrita, acesse com seus dados para prosseguir." title="Autenticar" />
        <section className="section-default section-authenticate">
          <Container>
            <Row className="justify-content-md-center">
              <Col className="section-authenticate-form" xs={12} md={8}>
                <AuthenticateForm />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="section-authenticate-facebook" xs={12} md={8}>
                <FacebookLogin
                  appId="2155875694706028"
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile"
                  callback={responseFacebook}
                  cssClass="btn btn-dark"
                  textButton="Entrar com Facebook"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="section-authenticate-register" xs={12} md={8}>
                <a href="/cadastre-se">Cadastre-se</a>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col className="section-authenticate-register" xs={12} md={8}>
                <a href="/esqueci-minha-senha">Esqueci minha senha</a>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Authenticate;
