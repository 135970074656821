import React, { useState } from "react";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";
import PasswordPanelComponent from "../../components/passwordPanelComponent";

import BannerPasswordRemoval from "../../assets/banners/password-removal.jpg";

function PasswordRemoval() {
  const [title] = useState("Retirada de Senhas - Tabelionato Camboriú");
  const [page] = useState("/retirada-de-senhas");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerPasswordRemoval}) no-repeat`} description="Retire sua senha e acompanhe o andamento da fila online." title="Retirada de Senhas" />
        <section className="section-default section-password-removal">
          <PasswordPanelComponent />
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default PasswordRemoval;
