import React, { useState, useEffect } from "react";
import {
  Button, Card, Col, Container, Form, Row, Tab, Tabs,
} from "react-bootstrap";

import Auth from "../../../auth";
import Password from "../../../password";
import BannerTEA from "../../../assets/TEA.png";

import "./index.css";

function MakePassword({ data: dataIni = false, callback, callbackModal, callbackModalResponse }) {
  const [data] = useState(dataIni);
  const [tab, setTab] = useState("#item-tab-0");
  const [loading, setLoading] = useState(false);
  const [dataService, setDataService] = useState(false);
  const [dataPriority, setDataPriority] = useState(false);

  const handleSubmit = async (event) => {
    //const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(!loading) {
      setLoading(true);
      setDataService(false);

      window.onunload();

      const token = Auth.isAuth() ? Auth.isAuth().token : false;
      const response = await Password.make({ data: {
        coordinates: {
          latitude: 0,
          longitude: 0
        },
        priority: dataPriority,
        service: dataService,
        unity: data.externalId
      }, token: token });

      window.onload();
      setLoading(false);
      callbackModal(false);
      callbackModalResponse(response);
    }
  };

  useEffect(() => {
    if(dataService > 0) {
      setTab("#item-tab-1");
    }
    else if(dataService === false) {
      setDataPriority(false);
      setTab("#item-tab-0");
    }
  }, [dataService]);

  useEffect(() => {
    if(dataPriority === -1) {
      setTab("#item-tab-2");
    }
    else if(dataPriority === -2) {
      setTab("#item-tab-1");
    }
  }, [dataPriority]);

  useEffect(() => {
    setTimeout(() => {
      callback(loading);
    }, 100);
  }, [callback, loading]);

  return (
    <Card className="section-form-make-password">
      <Card.Body>
        <Form id="form-make-password" noValidate onSubmit={handleSubmit}>
          <Tabs activeKey={tab}>
            <Tab eventKey={"#item-tab-0"}>
              <Container>
                <Row>
                  {
                    data ? (
                      data.services.map(item => {
                        return (
                          <Col className="section-form-make-password-col-services" key={`service-${item.externalId}`} xs={12} sm={6}>
                            <Button className="full" variant="dark" onClick={e => setDataService(e.currentTarget.getAttribute("data-id"))} data-id={item.externalId}>{item.name}</Button>
                          </Col>
                        );
                      })
                    ) : (
                      <Col><p>Nenhum resultado encontrado.</p></Col>
                    )
                  }
                </Row>
              </Container>
            </Tab>
            <Tab eventKey={"#item-tab-1"}>
              <Container>
                <Row>
                  <Col className="section-form-make-password-col-services" xs={12} sm={6}>
                    <Button className="full" variant="dark" type="submit" onClick={e => setDataPriority(1)}>Atendimento normal</Button>
                  </Col>
                  <Col className="section-form-make-password-col-services" xs={12} sm={6}>
                    <Button className="full" variant="danger" onClick={e => setDataPriority(-1)}>Atendimento prioritário</Button>
                  </Col>
                  <Col className="section-form-make-password-col-services" xs={12}>
                    <Button className="full" variant="dark" onClick={e => setDataService(false)}>Voltar</Button>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey={"#item-tab-2"}>
              <Container>
                <Row>
                  {
                    data ? (
                      <>
                        {
                          data.prioritys.map(item => {
                            return (
                              <Col className="section-form-make-password-col-prioritys" key={`priority-${item.externalId}`} xs={12} sm={item.externalId === 7 ? 12 : 6}>
                                <Button className="full" variant="danger" type="submit" onClick={e => setDataPriority(e.currentTarget.getAttribute("data-id"))} data-id={item.externalId}><span>{item.name}</span>{item.externalId === 7 ? (<img src={BannerTEA} alt="Banner TEA" />) : ""}</Button>
                              </Col>
                            );
                          })
                        }
                        <Col className="section-form-make-password-col-prioritys" xs={12}>
                          <Button className="full" variant="dark" onClick={e => setDataPriority(-2)}>Voltar</Button>
                        </Col>
                      </>
                    ) : (
                      <Col><p>Nenhum resultado encontrado.</p></Col>
                    )
                  }
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default MakePassword;
