import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Auth from "./auth";

import About from "./pages/about";
import Authenticate from "./pages/authenticate";
import ConfirmSms from "./pages/confirmSms";
import Contact from "./pages/contact";
import CookiesPolicy from "./pages/cookiesPolicy";
import Error from "./pages/error";
import EmolumentsTable from "./pages/emolumentsTable";
import ForgotPassword from "./pages/forgotPassword";
import Home from "./pages/home";
import Notice from "./pages/notice";
import NoticeMobile from "./pages/noticeMobile";
import Notices from "./pages/notices";
import OurServices from "./pages/ourServices";
import OurServicesOnline from "./pages/ourServicesOnline";
import PasswordRemoval from "./pages/passwordRemoval";

import PrivacyPolicy from "./pages/privacyPolicy";
import PrivacyPolicy30042021 from "./pages/privacyPolicy/30_04_2021.js";
import PrivacyPolicyDefault from "./pages/privacyPolicyDefault";

import Queries from "./pages/queries";
import SatisfactionSurvey from "./pages/satisfactionSurvey";
import Signup from "./pages/signup";

const CheckToken = async () => {
  if(Auth.isAuth() !== false) {
    await Auth.check({ token: Auth.isAuth().token });
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    { ... rest }
    render={props =>
      Auth.isAuth() !== false ? (
        <Component { ... props } />
      ) : (
        <Redirect to={{ pathname: "/autenticar", state: { from: props.location } }} />
      )
    }
  />
);

function Routes() {
  useEffect(() => {
    CheckToken();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sobre" exact component={About} />
        <Route path="/noticias/:page" exact component={Notices} />
        <Route path="/noticia/:id" exact component={Notice} />
        <Route path="/noticia-mobile/:id" exact component={NoticeMobile} />
        <Route path="/nossos-servicos" exact component={OurServices} />
        <Route path="/escritura-e-procuracao-online" exact component={OurServicesOnline} />
        <Route path="/consultas" exact component={Queries} />
        <Route path="/retirada-de-senhas" exact component={PasswordRemoval} />
        <Route path="/tabela-de-emolumentos" exact component={EmolumentsTable} />
        <Route path="/contato" exact component={Contact} />
        <Route path="/pesquisa-de-satisfacao" exact component={SatisfactionSurvey} />
        <Route path="/autenticar" exact component={Authenticate} />
        <Route path="/cadastre-se" exact component={Signup} />
        <Route path="/politica-de-cookies" exact component={CookiesPolicy} />
        <Route path="/politica-de-privacidade" exact component={PrivacyPolicy} />
        <Route path="/politica-de-privacidade-tabelionato-de-notas-protestos-camboriu-sc" exact component={PrivacyPolicyDefault} />
        <Route path="/politica-de-privacidade/30-04-2021" exact component={PrivacyPolicy30042021} />
        <Route path="/esqueci-minha-senha" exact component={ForgotPassword} />
        <Route path="/esqueci-minha-senha/:token" exact component={ForgotPassword} />
        <PrivateRoute path="/confirmar-sms" component={ConfirmSms} />
        <Route path="*" component={Error} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
