import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

window.onload = () => {
  setTimeout(() => {
    document.body.setAttribute("data-load", "true");
  }, 100);
};

window.onunload = () => {
  document.body.setAttribute("data-load", "false");
};

ReactDOM.render(<App />, document.getElementById("root"));
