import React from "react";

class Error extends React.Component {
  get() {
    return [
      {
        id: "User not found.",
        value: "Usuário não encontrado."
      },
      {
        id: "User password wrong.",
        value: "Senha incorreta."
      },
      {
        id: "Cellphone already registered.",
        value: "Celular já registrado."
      },
      {
        id: "Login already registered.",
        value: "Celular já registrado."
      },
      {
        id: "Email already registered.",
        value: "E-mail já registrado."
      },
      {
        id: "\"password\" length must be at least 6 characters long",
        value: "A senha deve ter no mínimo 6 caractéres."
      },
      {
        id: "\"cellphone\" length must be 11 characters long",
        value: "O celular deve ter 11 digitos."
      },
      {
        id: "\"name\" length must be at least 3 characters long",
        value: "O nome deve ter no mínimo 3 caractéres."
      },
      {
        id: "\"message\" length must be at least 3 characters long",
        value: "A mensagem deve ter no mínimo 3 caractéres."
      },
      {
        id: "\"code\" length must be 4 characters long",
        value: "O código deve ter 4 digitos."
      },
      {
        id: "User code invalid.",
        value: "O código é inválido."
      },
      {
        id: "User have another open password.",
        value: "O usuário tem outra senha em aberto."
      },
      {
        id: "Outside office hours.",
        value: "Fora do horário de atendimento."
      },
      {
        id: "\"passwordConfirmation\" must be one of [ref:password]",
        value: "A senha e a confirmação de senha devem ser idênticas."
      }
    ];
  }

  getType({ type }) {
    let index = this.get().findIndex(x => x.id === type);
    if(index !== -1) {
      return this.get()[index];
    }
    return false;
  }
}

export default new Error();
