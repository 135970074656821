import React from "react";
import { Row, Col } from "react-bootstrap";

import "./index.css";

import BrasaoBr from "../../assets/brasao-br.png";

function Logo({ className, inline }) {
  if (inline === "false") {
    return (
      <div className={`${className} collum`}>
        <Row className="logo-row">
          <Col className="col-img text-center" xs={12}>
            <img className="img-fluid" src={BrasaoBr} alt="Brasão Brasileiro" />
          </Col>
          <Col className="col-dsc" xs={12}>
            <span className="logo-header">Tabelionato de Notas e Protestos</span>
            <span className="logo-footer">DE CAMBORIÚ - SC</span>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div className={`${className} inline`}>
      <Row className="logo-row">
        <Col className="col-img" xs={0} sm={2}>
          <img className="img-fluid" src={BrasaoBr} alt="Brasão Brasileiro" />
        </Col>
        <Col className="col-dsc" xs={12} sm={10}>
          <span className="logo-header">Tabelionato de Notas e Protestos</span>
          <span className="logo-footer">DE CAMBORIÚ - SC</span>
        </Col>
      </Row>
    </div>
  );
}

export default Logo;
