import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
} from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerOurServices from "../../assets/banners/our-services.jpg";

function CookiesPolicy(props) {
  const [title] = useState("Política de Cookies - Tabelionato Camboriú");
  const [page] = useState("/politica-de-cookies");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerOurServices}) no-repeat`} title="Política de cookies" />
        <section className="section-default section-our-services">
          <Container>
            <Row>
              <Col className="cookies-policy-content-box" xs={12}>
                <h2 className="default-sub-title half">O que é um cookie?</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Cookies são pequenos arquivos de texto armazenados em seu dispositivo (computador, tablet, smartphone ou outro dispositivo) para aprimorar sua experiência no site.</p>
                <p className="default-sub-description text-justify">A maioria dos navegadores da Internet aceitam cookies; entretanto, os usuários podem configurar seus navegadores para recusar certos tipos de cookies ou cookies específicos. Além disso, os usuários podem apagar os cookies a qualquer momento.</p>

                <h2 className="default-sub-title half">Por que usamos cookies?</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Usamos cookies para lembrar preferências, facilitar a experiência de navegação do usuário e também para manter o usuário conectado ao seu acesso restrito por um período maior de tempo.</p>

                <h2 className="default-sub-title half">Cookies essenciais</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Sempre usamos os cookies essenciais. Eles nos permitem fornecer a você os recursos essenciais do nosso site, como navegação no site ou login nas áreas protegidas. Usá-los é do seu interesse, portanto, todas as leis de proteção de dados pessoais aplicáveis nos permitem usá-los livremente.</p>

                <h2 className="default-sub-title half">Cookies de interação com o cliente</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Esses cookies permitem que nosso site armazene temporariamente suas informações de contato no caso de uma consulta e entre em contato com você. Estes dados não são compartilhados com terceiros.</p>

                <h2 className="default-sub-title half">Cookies de mídia social</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Esses cookies permitem que as redes sociais registrem seu comportamento ao usar botões de engajamento social (como o botão “Curtir”). Os dados que esses cookies coletam são compartilhados com anunciantes terceirizados.</p>

                <p className="default-sub-description text-justify">Estamos comprometidos com a transparência total em relação à sua privacidade ao usar nosso site e não fazemos o compartilhamento dessas informações.</p>

                <h2 className="default-sub-title half">Excluindo ou desativando cookies</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Você pode excluir ou desabilitar os cookies nas configurações do seu navegador. No entanto, você deve ter em mente que excluir ou desativar os cookies pode causar transtornos ao usar nosso site, o que não aconteceria se você consentisse com o uso de cookies.</p>
                <p className="default-sub-description text-justify">Nos links a seguir, você encontrará informações sobre como excluir ou desativar os cookies nas configurações do seu navegador:</p>

                <p className="default-sub-description text-justify">- <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-br" target="_blank" rel="noopener noreferrer">Chrome</a></p>
                <p className="default-sub-description text-justify">- <a href="https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop" target="_blank" rel="noopener noreferrer">Firefox</a></p>
                <p className="default-sub-description text-justify">- <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></p>
                <p className="default-sub-description text-justify">- <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/" target="_blank" rel="noopener noreferrer">Opera</a></p>
                <p className="default-sub-description text-justify">- <a href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener noreferrer">Edge</a></p>

                <h2 className="default-sub-title half">Atualizações em nossa política</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Podemos alterar ou atualizar nossa Política de Cookies. Notificaremos sobre alterações feitas nesta Política de Cookies, conforme a necessidade, e atualizaremos a data de última modificação na parte inferior desta Política de Cookies. A continuação do uso de nossos Serviços ratifica sua aceitação de nossa Política de Cookies e alterações posteriores.</p>

                <h2 className="default-sub-title half">Fale conosco</h2>
                <div className="default-sub-title-separator" />

                <p className="default-sub-description text-justify">Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco.</p>

                <p className="default-sub-description text-justify">(49) 2049.4353 | contato@threepixels.com.br</p>

                <p className="default-sub-description text-justify">Última modificação: 30/04/2021</p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default CookiesPolicy;
