import React, { useState, useEffect } from "react";
import {
  Alert, Button, Col, Container, Modal, Row, Spinner,
} from "react-bootstrap";

import "./index.css";

import Api from "../../services/api";
import Auth from "../../auth";
import Logo from "../../pages/logo";
import Password from "../../password";
import Ws from "../../services/ws";

import MakePasswordForm from "../../services/forms/makePassword";

import Error from "../../error";

function PasswordPanelComponent() {
  const [wsConnected,  setWsConnected] = useState(false);
  const [wsNexts, setWsNexts] = useState([]);
  const [wsCalls, setWsCalls] = useState([]);
  const [wsNow, setNow] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [modalMP, setModalMP] = useState(false);
  const [modalResponse, setModalResponse] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [passwordNow, setPasswordNow] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    Ws.on("connect", () => {
      setWsConnected(true);
    });

    Ws.on("disconnect", () => {
      setWsConnected(false);
    });

    Ws.on("sga", (message) => {
      Password.update({ data: message });
      setPasswordNow(Password.isPassword());
    });

    Ws.on("sgaRemove", (message) => {
      Password.remove();
      setPasswordNow(false);
    });

    Ws.on("panel", (message) => {
      setNow(message.now);
      setWsNexts(message.nexts);
      setWsCalls(message.calls);
    });

    async function fetchData() {
      if(Password.isPassword()) {
        const response = await Api.get(`/sga/${Password.isPassword().id}`, {
          headers: {
            Authorization: `Bearer ${Auth.isAuth() ? Auth.isAuth().token : false}`
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          return error.response.data;
        });

        if(response.success) {
          if(response.data.status[response.data.status.length-1].type > 2) {
            Password.remove();
          }
          else {
            Password.update({ data: response.data });
            setPasswordNow(Password.isPassword());
          }
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(wsNow) {
      let text = `Senha,${wsNow.password.initials},${wsNow.password.number},${wsNow.ticketWindow.name},${wsNow.ticketWindow.number},${wsNow.floor}`;
      setTimeout(function() {
        var speech = new SpeechSynthesisUtterance(text);
        speech.lang = "pt-br";
        speechSynthesis.speak(speech);
      }, 100);
    }
  }, [wsNow]);

  useEffect(() => {
    setTimeout(() => {
      if(modalResponse !== null) {
        if(modalResponse.success === false) {
          setAlertType("danger");
          const message = Error.getType({ type: modalResponse.error.details[0].message });
          if(message) {
            setAlertMessage(message.value);
          }
        }
        else {
          setAlertType("success");
          setAlertMessage("Senha gerada com sucesso.");
        }
      }
    }, 100);
  }, [modalResponse]);

  // useEffect(() => {
  //   if(modalData) {
  //     //console.log(modalData);
  //   }
  // }, [modalData]);

  const handleMakePassword = async () => {
    if(Auth.isAuth() === false) {
      return window.location = "/autenticar";
    }

    if(!modalLoading) {
      setModalLoading(true);
      if(!modalData) {
        const response = await Api.get("/sga/unity", {
          headers: {
            Authorization: `Bearer ${Auth.isAuth() ? Auth.isAuth().token : false}`
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          return error.response.data;
        });

        if(response.success) {
          setModalData(response.data[0]);
        }
      }
    }
    setModalLoading(false);
    setModalMP(true);
  };

  return (
    <Container className="password-panel-component">
      <Modal
        size="lg"
        show={modalMP}
        onHide={() => setModalMP(false)}
      >
        <Modal.Header closeButton>
          <Container>
            <Row className="justify-content-md-center">
              <Col className="password-panel-component-logo"><Logo className="logo dark" inline="true" /></Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <MakePasswordForm data={modalData} callback={setModalLoading} callbackModal={setModalMP} callbackModalResponse={setModalResponse} />
        </Modal.Body>
      </Modal>

      <Row className="password-panel-row-sts-now">
        <Col><div className={wsConnected ? "password-panel-row-sts-con" : "password-panel-row-sts-dis"} /> {wsConnected ? "Conectado" : "Descontectado"}</Col>
      </Row>
      <Row>
        <Col className="password-panel-col-pwd-hys nexts" xs={12}>
          <h4>Próximas chamadas</h4>
        </Col>
        <Col className="password-panel-col-pwd-dsc nexts" xs={12}>
          <Row>
            {
              (
                [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19
                ]
              ).map((index) => (
                <Col className={ wsNexts.length < (index + 1) ? "vis-hide" : "" } xs={2} sm={true} key={index}>
                  {
                    wsNexts[index] !== undefined ? (
                      <>
                        <h4>{ wsNexts[index].password.initials }{ wsNexts[index].password.number }</h4>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
      <Row className="password-panel-row-pwd-now">
        <Col className="password-panel-col-pwd-now" xs={6}>
          <div className="section-content">
            <div className="section-content-center">
              <h3>{ wsNow ? wsNow.password.initials : "A" }{ wsNow ? wsNow.password.number : "000" }</h3>
            </div>
          </div>
        </Col>
        <Col className="password-panel-col-pwd-now-dsc" xs={6}>
          <div className="section-content">
            <div className="section-content-center">
              <h4>
                <span>{ wsNow ? wsNow.ticketWindow.name : "Guichê" }</span>
                <br />
                <span className="password-panel-now-gnum">{ wsNow ? wsNow.ticketWindow.number : "0" }</span>
                <br />
                <span className="password-panel-now-gfloor">{ wsNow ? wsNow.floor : "Térreo" }</span>
              </h4>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="password-panel-col-pwd-hys" xs={12}>
          <h4>Histórico de chamadas</h4>
        </Col>
        <Col className="password-panel-col-pwd-dsc" xs={12}>
          <Row>
            {
              ([0, 1, 2, 3, 4]).map((index) => (
                <Col className={ wsCalls.length < (index + 1) ? "vis-hide" : "" } xs={4} sm={true} key={index}>
                  {
                    wsCalls[index] !== undefined ? (
                      <>
                        <h4>{ wsCalls[index].password.initials }{ wsCalls[index].password.number }</h4>
                        <h5>
                          { wsCalls[index].ticketWindow.name }:&nbsp;
                          <span>{ wsCalls[index].ticketWindow.number }</span>
                          <br />
                          <span>{ wsCalls[index].floor.substr(0, 12) }...</span>
                        </h5>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
      {
        passwordNow ? (
          <>
            <Row>
              <Col className="password-panel-col-pwd-hys" xs={12}>
                <h4>Senha pessoal</h4>
              </Col>
              <Col className="password-panel-col-pwd-dsc" xs={12}>
                <Row className="justify-content-center">
                  <Col xs={8} sm={4}>
                    <div className={`password-panel-ticket ${passwordNow.status[passwordNow.status.length-1].type > 1 ? "active" : ""}`}>
                      <h5>Tabelionato Camboriú</h5>
                      <p className="password-panel-ticket-date">{ Password.getDate({ date: passwordNow.status[0].date }) }</p>
                      <p className="password-panel-ticket-password">{ passwordNow.password.initials }{ passwordNow.password.number }</p>
                      <p className="password-panel-ticket-priority">{ passwordNow.priority.name }</p>
                      <p className="password-panel-ticket-name">{ Auth.isAuth() ? Auth.isAuth().name : "" }</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (<></>)
      }
      <Row>
        <Col className="password-panel-col-pwd-new" xs={12}>
          <Button variant="light" type="button" onClick={handleMakePassword} disabled={modalLoading ? true : false}>
            {
              modalLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <></>
              )
            }
            { modalLoading ? " Aguarde..." : " Gerar senha" }
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            alertMessage === "" ? "" : (
              <Alert variant={alertType}>{alertMessage}</Alert>
            )
          }
        </Col>
      </Row>
    </Container>
  );
}

export default PasswordPanelComponent;
