import React, { useState } from "react";
import { Alert, Button, Col, Form, Spinner } from "react-bootstrap";

import "./index.css";

import Email from "../../../email";
import Error from "../../../error";

function Contact() {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(!loading) {
      setAlertType("danger");
      setAlertMessage("");
      setLoading(true);

      if(form.checkValidity() === false) {
        setAlertMessage("Preencha os campos obrigatórios.");
      }

      setValidated(true);

      const response = await Email.contact({ data: {
        email,
        message,
        name
      }});

      if(response.success === false) {
        const message = Error.getType({ type: response.error.details[0].message });
        if(message) {
          setAlertMessage(message.value);
        }
      }
      else {
        setAlertType("success");
        setAlertMessage("E-mail enviado com sucesso.");
        form.reset();
      }

      setLoading(false);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Nome:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nome"
            maxLength="255"
            onChange={e => setName(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com o seu nome, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>E-mail:</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="E-mail"
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com um email válido, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Mensagem:</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows="5"
            placeholder="Mensagem"
            maxLength="1000"
            onChange={e => setMessage(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Preencha com uma mensagem de dúvida, sugestão ou reclamação, é obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          {
            alertMessage === "" ? "" : (
              <Alert variant={alertType}>{alertMessage}</Alert>
            )
          }
        </Form.Group>
      </Form.Row>
      <div className="text-right">
        <Button variant="dark" type="submit" disabled={loading ? true : false}>
          {
            loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <></>
            )
          }
          { loading ? " Aguarde..." : " Enviar" }
        </Button>
      </div>
    </Form>
  );
}

export default Contact;
