import React from "react";

import Api from "./services/api";
import Storage from "./storage";

class Auth extends React.Component {
  isAuth() {
    try {
      const user = JSON.parse(Storage.get("user"));
      if(user === null) {
        return false;
      }
      return user;
    } catch(error) {
      return false;
    }
  }

  async changePassword({ password, passwordConfirmation, token }) {
    const response = await Api.put("/auth/change-password", { password, passwordConfirmation }, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }

  async confirmSms({ code, token }) {
    const response = await Api.post("/auth/confirm-sms", { code }, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(response.success) {
      const user = JSON.parse(Storage.get("user"));
      user.sms.confirmed = true;
      Storage.set("user", JSON.stringify(user));
    }

    return response;
  }

  async forgotPassword({ email }) {
    const response = await Api.post("/auth/forgot-password", { email }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }

  async resendSms({ token }) {
    let valid = false;
    const sms = JSON.parse(Storage.get("sms"));
    if(sms) {
      if(((Date.now() - sms.date) / 1000) > 60) {
        valid = true;
      }
    }
    else {
      valid = true;
    }

    if(valid) {
      const response = await Api.post("/auth/resend-sms", {}, {
        headers: {"Authorization": "bearer " + token}
      }).then(response => {
        return response.data;
      }).catch(error => {
        return error.response.data;
      });

      if(response.success) {
        Storage.set("sms", JSON.stringify({
          date: Date.now()
        }));
      }
      return response;
    }
    return false;
  }

  async signIn({ login, password }) {
    const response = await Api.post("/auth", { login, password }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(response.success) {
      Storage.set("user", JSON.stringify(response.data));
    }

    return response;
  }

  async signInFb({ access_token }) {
    const response = await Api.post("/auth/facebook", { access_token }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(response.success) {
      Storage.set("user", JSON.stringify(response.data));
    }

    return response;
  }

  async signUp({ data }) {
    console.log(data);
    const response = await Api.post("/auth/register", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(response.success) {
      Storage.set("user", JSON.stringify(response.data));
    }

    return response;
  }

  async signOut({ token }) {
    const response = await Api.post("/auth/logout", {}, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    Storage.remove("user");
    Storage.remove("password");
    return response;
  }

  async check({ token }) {
    const response = await Api.post("/auth/check-token", {}, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(!response.success) {
      Storage.remove("user");
      Storage.remove("password");
      window.location = "";
    }

    return response;
  }
}

export default new Auth();
