import React from "react";
import { Container } from "react-bootstrap";

import "./index.css";

function Banner({ background, description, title }) {
  return (
    <section className="section-banner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${background}` }}>
      <div className="section-content">
        <div className="section-content-center">
          <Container>
            <h1 className="default-title text-center">{title}</h1>
            <h3 className="default-description text-center">{description}</h3>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Banner;
