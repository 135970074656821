import React from "react";

import Api from "./services/api";

class Email extends React.Component {
  async certificate({ data }) {
    const response = await Api.post("/email/certificate", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    return response;
  }

  async contact({ data }) {
    const response = await Api.post("/email/contact", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }

  async contactOurServices({ data }) {
    const response = await Api.post("/email/contact-our-services", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }

  async procuration({ data }) {
    const response = await Api.post("/email/procuration", data).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });
    return response;
  }
}

export default new Email();
