import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import ChangePasswordForm from "../../services/forms/changePassword";
import ForgotPasswordForm from "../../services/forms/forgotPassword";

import BannerAuthenticate from "../../assets/banners/authenticate.jpg";

function ForgotPassword(props) {
  const [title] = useState("Esqueci minha senha - Tabelionato Camboriú");
  const [page] = useState("/esqueci-minha-senha");
  const [token] = useState(props.match.params.token);

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerAuthenticate}) no-repeat`} description="Altere sua senha para ter acesso novamente para a retirada de senhas." title="Esqueci minha senha" />
        <section className="section-default section-forgot-password">
          <Container>
            <Row className="justify-content-md-center">
              <Col className="section-authenticate-form" xs={12} md={8}>
                {
                  token ? (
                    <ChangePasswordForm token={token} />
                  ) : (
                    <ForgotPasswordForm />
                  )
                }
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default ForgotPassword;
