import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
} from "react-bootstrap";

import "./index.css";

import ShareButtonComponent from "../../components/shareButtonComponent";

import NoticeApi from "../../notice";
import ImagesApi from "../../images";

function NoticeMobile(props) {
  const [title, setTitle] = useState("Notícia - Tabelionato Camboriú");
  const [shareUrl] = useState(window.location.href);
  const [noticeId] = useState(props.match.params.id);
  const [notice, setNotice] = useState(false);

  const findNotice = async () => {
    const response = await NoticeApi.findOne({ id: noticeId });
    if(response.success) {
      setTitle(`${response.data.title} - Tabelionato Camboriú`);
      return setNotice(response.data);
    }
  };

  useEffect(() => {
    findNotice();
  });

  return (
    <div>
      <main>
        <section className="section-default section-notices">
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  {
                    notice ? (
                      <img className="img-fluid" src={ImagesApi.render({ image: notice.images[0] })} alt={title} />
                    ) : ""
                  }
                </Col>
              </Row>
              <Row>
                <Col className="notice-date-box">{NoticeApi.getDate({ date: notice.createdAt })}</Col>
              </Row>
              <Row>
                <Col className="notice-content-box" dangerouslySetInnerHTML={{__html: notice.content }}></Col>
              </Row>
              <Row>
                <Col className="share-button-box">
                  <ShareButtonComponent title={title} url={shareUrl} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="fb-comments" data-href={shareUrl} data-width="100%" data-numposts="5" />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </main>
    </div>
  );
}

export default NoticeMobile;
