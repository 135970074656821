import React, { useState } from "react";

import "./index.css";

import Banner from "../banner";
import Footer from "../footer";
import Header from "../header";

import BannerNotFound from "../../assets/banners/not-found.jpg";

function Error() {
  const [title] = useState("Página não encontrada - Tabelionato Camboriú");
  const [page] = useState("*");

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerNotFound}) no-repeat`} description="A URL digitada não foi encontrada, esta página não existe ou foi alterada para uma nova sessão. Tente procurar navegando pelo menu superior." title="Erro" />
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Error;
