import React from "react";
import Timezone from "moment-timezone";

import Api from "./services/api";
import Storage from "./storage";

class Password extends React.Component {
  getDate({ date }) {
    return Timezone(date)
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY HH:mm:ss");
  }

  isPassword() {
    try {
      const password = JSON.parse(Storage.get("password"));
      if(password === null) {
        return false;
      }
      return password;
    } catch(error) {
      return false;
    }
  }

  async make({ data, token }) {
    const response = await Api.post("/sga/generate-password", data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response.data;
    });

    if(response.success) {
      Storage.set("password", JSON.stringify(response.data));
    }
    return response;
  }

  update({ data }) {
    Storage.set("password", JSON.stringify(data));
    return data;
  }

  remove() {
    Storage.remove("password");
    return true;
  }
}

export default new Password();
