import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import CardServiceComponent from "../../components/cardServiceComponent";
import Footer from "../footer";
import Header from "../header";

import BannerOurServices from "../../assets/banners/our-services.jpg";

function OurServices() {
  const [title] = useState("Nossos Serviços - Tabelionato Camboriú");
  const [page] = useState("/nossos-servicos");

  const [servicesA] = useState([
    {
      id: 1,
      description: false,
      header: "APONTAMENTO",
      icon: ["fas", "hand-point-right"],
    },
    {
      id: 2,
      description: false,
      header: "CANCELAMENTO",
      icon: ["fas", "times"],
    },
  ]);

  const [servicesB] = useState([
    {
      id: 3,
      description: false,
      header: "ATA NOTARIAL",
      icon: ["fas", "pencil-alt"],
    },
    {
      id: 4,
      description: false,
      header: "AUTENTICAÇÕES",
      icon: ["fas", "certificate"],
    },
    {
      id: 5,
      description: false,
      header: "ESCRITURA PÚBLICA",
      icon: ["fas", "home"],
    },
    {
      id: 6,
      description: false,
      header: "RECONHECIMENTO DE FIRMA",
      icon: ["fas", "industry"],
    },
    {
      id: 7,
      description: false,
      header: "TESTAMENTO",
      icon: ["fas", "file-alt"],
    },
    {
      id: 8,
      description: false,
      header: "PROCURAÇÃO",
      icon: ["fas", "users"],
    },
    {
      id: 9,
      description: false,
      header: "INVENTÁRIO/DIVÓRCIO EXTRAJUDICIAL",
      icon: ["fas", "file-alt"],
    },
    {
      id: 10,
      description: false,
      header: "APOSTILAMENTO",
      icon: ["fas", "file-alt"],
    },
    {
      id: 11,
      description: false,
      header: "COMUNICAÇÃO DE VENDA",
      icon: ["fas", "comment"],
    },
  ]);

  const handleOnload = () => {
    document.title = title;
  };

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerOurServices}) no-repeat`} description="Conheça os serviços que oferecemos." title="Nossos Serviços" />
        <section className="section-default section-our-services">
          <Container>
            <Row>
              <Col className="our-services-col-main-a">
                <h4 className="default-sub-title">Protesto de Títulos e Documentos</h4>
                <div className="default-sub-title-separator" />
                <Container>
                  <Row>
                    {
                      servicesA.map(item => (
                        <Col xs={12} md={6} className="our-services-col-sub" key={item.id}>
                          <CardServiceComponent
                            description={item.description}
                            header={item.header}
                            icon={item.icon}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col className="our-services-col-main-b">
                <h4 className="default-sub-title">Notas</h4>
                <div className="default-sub-title-separator" />
                <Container>
                  <Row>
                    {
                      servicesB.map(item => (
                        <Col xs={12} md={6} className="our-services-col-sub" key={item.id}>
                          <CardServiceComponent
                            description={item.description}
                            header={item.header}
                            icon={item.icon}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default OurServices;
