import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Row,
} from "react-bootstrap";

import "./index.css";
import Banner from "../banner";
import CardNoticeComponent from "../../components/cardNoticeComponent";
import Footer from "../footer";
import Header from "../header";
import ShareButtonComponent from "../../components/shareButtonComponent";

import BannerNotices from "../../assets/banners/notices.jpg";

import NoticeApi from "../../notice";
import ImagesApi from "../../images";

function Notice(props) {
  const [title, setTitle] = useState("Notícia - Tabelionato Camboriú");
  const [page] = useState("/noticias");
  const [shareUrl] = useState(window.location.href);
  const [noticeId] = useState(props.match.params.id);
  const [notice, setNotice] = useState(false);
  const [notices, setNotices] = useState([]);

  const useMountEffect = (fun) => useEffect(fun, []);

  const handleOnload = () => {
    document.title = title;
  };

  const findNotice = async () => {
    const response = await NoticeApi.findOne({ id: noticeId });
    if(response.success) {
      setTitle(`${response.data.title} - Tabelionato Camboriú`);
      return setNotice(response.data);
    }
  };

  const findNotices = async () => {
    const response = await NoticeApi.find({ page: 1 });
    if(response.success) {
      const itens = [];
      response.data.docs.map(item => {
        if((item.id !== noticeId) && (itens.length < 5)) {
          return itens.push(item);
        }
        return false;
      });
      return setNotices(itens);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.title = title;
    }, 100);
  }, [title]);

  useMountEffect(() => {
    findNotice();
    findNotices();
  });
  
  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerNotices}) no-repeat`} title={notice.title} />
        <section className="section-default section-notices">
          <Container>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
                <Breadcrumb.Item href="/noticias/1">Notícias</Breadcrumb.Item>
                <Breadcrumb.Item active>{notice.title}</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12}>
                    {
                      notice ? (
                        <img className="img-fluid" src={ImagesApi.render({ image: notice.images[0] })} alt={title} />
                      ) : ""
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="notice-date-box">{NoticeApi.getDate({ date: notice.createdAt })}</Col>
                </Row>
                <Row>
                  <Col className="notice-content-box" dangerouslySetInnerHTML={{__html: notice.content }}></Col>
                </Row>
                <Row>
                  <Col className="share-button-box">
                    <ShareButtonComponent title={title} url={shareUrl} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="fb-comments" data-href={shareUrl} data-width="100%" data-numposts="5" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <aside>
                  <section className="section-last-notices">
                    <Container>
                      <Row>
                        <Col>
                          <h4 className="default-sub-title">Últimas notícias</h4>
                          <div className="default-sub-title-separator" />
                        </Col>
                      </Row>
                      <Row>
                        {
                          notices.map(item => (
                            <Col className="last-notices-col" xs={12} key={item.id}>
                              <CardNoticeComponent
                                description={NoticeApi.getContentPrev({ content: item.content })}
                                title={item.title}
                                url={`/noticia/${item.id}`}
                                button={false}
                                date={NoticeApi.getDate({ date: item.createdAt })}
                              />
                            </Col>
                          ))
                        }
                        {
                          notices.length === 0 ? (
                            <p className="default-sub-description text-center w-100">Nenhum resultado encontrado.</p>
                          ) : ""
                        }
                      </Row>
                    </Container>
                  </section>
                </aside>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Notice;
