import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import Banner from "../banner";
import CardNoticeComponent from "../../components/cardNoticeComponent";
import Footer from "../footer";
import Header from "../header";
import PaginationComponent from "../../components/paginationComponent";

import BannerNotices from "../../assets/banners/notices.jpg";

import Notice from "../../notice";
import ImagesApi from "../../images";

function Notices(props) {
  const [title] = useState("Notícias - Tabelionato Camboriú");
  const [page] = useState("/noticias");
  const [notices, setNotices] = useState([]);
  const [noticesPage] = useState(props.match.params.page);
  const [noticesPaginate, setNoticesPaginate] = useState(false);

  const useMountEffect = (fun) => useEffect(fun, []);

  const handleOnload = () => {
    document.title = title;
  };

  const findNotices = async () => {
    const response = await Notice.find({ page: noticesPage });
    if(response.success) {
      setNoticesPaginate({
        page: response.data.page,
        pages: response.data.pages,
        total: response.data.total,
        limit: response.data.limit
      });
      return setNotices(response.data.docs);
    }
  };

  useMountEffect(() => {
    findNotices();
  });

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <Banner background={`url(${BannerNotices}) no-repeat`} description="Fique por dentro das novidades." title="Notícias" />
        <section className="section-default section-notices">
          <Container>
            <Row>
              {
                notices.map(item => (
                  <Col className="notices-col" xs={12} md={6} lg={4} key={item.id}>
                    <CardNoticeComponent
                      description={Notice.getContentPrev({ content: item.content })}
                      img={ImagesApi.render({ image: item.images[0] })}
                      title={item.title}
                      url={`/noticia/${item.id}`}
                      date={Notice.getDate({ date: item.createdAt })}
                    />
                  </Col>
                ))
              }
              {
                notices.length === 0 ? (
                  <Col className="notices-col" xs={12}>
                    <p className="default-sub-description text-center">Nenhum resultado encontrado.</p>
                  </Col>
                ) : ""
              }
            </Row>
            <Row className="section-notices-pagination">
              {
                noticesPaginate !== false ? (
                  <Col>
                    <PaginationComponent paginate={noticesPaginate} url="/noticias" />
                  </Col>
                ) : ""
              }
            </Row>
          </Container>
        </section>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Notices;
