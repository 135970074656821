import React, { useState } from "react";
import {
  Alert, Button, Card, Col, Form, Nav, Row, Spinner, Tab, Tabs,
} from "react-bootstrap";

import "./index.css";

import SatisfactionSurveyApi from "../../../satisfactionSurvey";
import Error from "../../../error";

function SatisfactionSurvey() {
  const [validated, setValidated] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("Preencha as respostas das questões 1, 2 e 3, são obrigatórias.");
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("#item-tab-0");
  const [sugestion, setSugestion] = useState("");
  const [questionsItems] = useState([{
    items: [{
      id: "questions-1-a",
      name: "questions-1",
      title: "a. Cancelamento/Certidão de protesto.",
      value: "A",
    },
    {
      id: "questions-1-b",
      name: "questions-1",
      title: "b. Encaminhamento de título para protesto.",
      value: "B",
    },
    {
      id: "questions-1-c",
      name: "questions-1",
      title: "c. Autenticação/Reconhecimento de firma.",
      value: "C",
    },
    {
      id: "questions-1-d",
      name: "questions-1",
      title: "d. Procurações e/ou substabelecimento",
      value: "D",
    },
    {
      id: "questions-1-e",
      name: "questions-1",
      title: "e. Certidões notas (procuração, escritura, etc)",
      value: "E",
    },
    {
      id: "questions-1-f",
      name: "questions-1",
      title: "f. Ata notarial.",
      value: "F",
    },
    {
      id: "questions-1-g",
      name: "questions-1",
      title: "g. Escritura declaratória",
      value: "G",
    },
    {
      id: "questions-1-h",
      name: "questions-1",
      title: "h. Escrituras com valor (compra venda, permuta, etc)",
      value: "H",
    },
    {
      id: "questions-1-i",
      name: "questions-1",
      title: "i. Outros.",
      value: "I",
    }]
  },
  {
    items: [{
      id: "questions-2-a",
      name: "questions-2-a",
      title: "a. Você teve o serviço solicitado atendido?",
      answers: [{
        id: "yes",
        title: "Sim",
        value: true,
      },
      {
        id: "not",
        title: "Não",
        value: false,
      }],
    },
    {
      id: "questions-2-b",
      name: "questions-2-b",
      title: "b. Você teve suas dúvidas esclarecidas?",
      answers: [{
        id: "yes",
        title: "Sim",
        value: true,
      },
      {
        id: "not",
        title: "Não",
        value: false,
      }],
    },
    {
      id: "questions-2-c",
      name: "questions-2-c",
      title: "c. Você recomendaria esse tabelionato?",
      answers: [{
        id: "yes",
        title: "Sim",
        value: true,
      },
      {
        id: "not",
        title: "Não",
        value: false,
      }],
    }]
  },
  {
    items: [{
      id: "questions-3-a",
      name: "questions-3-a",
      title: "a. Atendimento telefônico",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    },
    {
      id: "questions-3-b",
      name: "questions-3-b",
      title: "b. Localização",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    },
    {
      id: "questions-3-c",
      name: "questions-3-c",
      title: "c. Tempo de espera",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    },
    {
      id: "questions-3-d",
      name: "questions-3-d",
      title: "d. Tempo de atendimento",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    },
    {
      id: "questions-3-e",
      name: "questions-3-e",
      title: "e. Nossas instalações",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    },
    {
      id: "questions-3-f",
      name: "questions-3-f",
      title: "f. Avaliação geral",
      answers: [{
        id: "good",
        title: "Bom",
        value: "good",
      },
      {
        id: "average",
        title: "Médio",
        value: "average",
      },
      {
        id: "bad",
        title: "Ruim",
        value: "bad",
      }],
    }]
  }]);
  const [questions] = useState([
    {
      id: 1,
      content: (
        <div className="questions-tab-1">
          <h4 className="default-sub-title">1) Indique qual / quais serviço(s) você utilizou:</h4>
          <div className="default-sub-title-separator" />
          <Form.Row>
            {
              questionsItems[0].items.map(item => (
                <Form.Group className="questions-tab-1-col-main" as={Col} xs={12} key={item.id}>
                  <Form.Check className="question-items-1 form-check-dark full" type="radio" id={item.id}>
                    <Form.Check.Input type="radio" name={item.name} value={item.value} onChange={e => handleChange(e)} />
                    <Form.Check.Label>{item.title}</Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              ))
            }
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} className="text-right">
              <Button variant="dark" type="button" onClick={() => setQuestion("#item-tab-1")}>Continuar</Button>
            </Form.Group>
          </Form.Row>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className="questions-tab-2">
          <h4 className="default-sub-title">2) Responda as seguintes questões:</h4>
          <div className="default-sub-title-separator" />
          {
            questionsItems[1].items.map(item => (
              <Form.Row className="justify-content-md-center" key={item.id}>
                <Form.Group className="questions-tab-2-col-main" as={Col} xs={12}>
                  <h5 className="default-sub-description text-center">{item.title}</h5>
                  <Row>
                    <Col className="questions-tab-2-col text-center">
                      {
                        item.answers.map(subitem => (
                          <Form.Check className="question-items-2 form-check-dark" type="radio" id={`${item.id}-${subitem.id}`} key={`${item.id}-${subitem.id}`}>
                            <Form.Check.Input type="radio" name={item.name} value={subitem.value} onChange={e => handleChange(e)} />
                            <Form.Check.Label>{subitem.title}</Form.Check.Label>
                          </Form.Check>
                        ))
                      }
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Row>
            ))
          }
          <Form.Row>
            <Form.Group as={Col} xs={12} className="text-right">
              <Button variant="dark" type="button" onClick={() => setQuestion("#item-tab-2")}>Continuar</Button>
            </Form.Group>
          </Form.Row>
        </div>
      ),
    },
    {
      id: 3,
      content: (
        <div className="questions-tab-3">
          <h4 className="default-sub-title">3) Classifique nossos serviços:</h4>
          <div className="default-sub-title-separator" />
          {
            questionsItems[2].items.map(item => (
              <Form.Row className="justify-content-md-center" key={item.id}>
                <Form.Group className="questions-tab-3-col-main" as={Col} xs={12}>
                  <h5 className="default-sub-description text-center">{item.title}</h5>
                  <Row>
                    <Col className="questions-tab-3-col text-center">
                      {
                        item.answers.map(subitem => (
                          <Form.Check className="question-items-3 form-check-dark" type="radio" id={`${item.id}-${subitem.id}`} key={`${item.id}-${subitem.id}`}>
                            <Form.Check.Input type="radio" name={item.name} value={subitem.value} onChange={e => handleChange(e)} />
                            <Form.Check.Label>{subitem.title}</Form.Check.Label>
                          </Form.Check>
                        ))
                      }
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Row>
            ))
          }
          <Form.Row>
            <Form.Group as={Col} xs={12} className="text-right">
              <Button variant="dark" type="button" onClick={() => setQuestion("#item-tab-3")}>Continuar</Button>
            </Form.Group>
          </Form.Row>
        </div>
      ),
    },
    {
      id: 4,
      content: (
        <div className="questions-tab-4">
          <h4 className="default-sub-title">4) Quais sugestões você nos daria para melhorar a qualidade do nosso atendimento?</h4>
          <div className="default-sub-title-separator" />
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Mensagem:</Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                onChange={e => setSugestion(e.currentTarget.value)}
              />
            </Form.Group>
          </Form.Row>
        </div>
      ),
    },
  ]);

  const handleChange = (event) => {
    setAlertType("danger");
    if(document.querySelectorAll("[name=questions-1]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-2-a]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-2-b]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-2-c]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-a]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-b]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-c]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-d]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-e]:checked").length < 1 ||
       document.querySelectorAll("[name=questions-3-f]:checked").length < 1) {
      setAlertMessage("Preencha as respostas das questões 1, 2 e 3, são obrigatórias.");
      setValidated(false);
    }
    else {
      setAlertMessage("");
      setValidated(true);
    }
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    handleChange(event);

    if(!loading) {
      setAlertType("danger");
      setLoading(true);

      if(validated) {
        const response = await SatisfactionSurveyApi.create({ data: {
          question: {
            questionAnswered: (document.querySelectorAll("[name=questions-2-b]:checked")[0].value === "true"),
            recommend: (document.querySelectorAll("[name=questions-2-c]:checked")[0].value === "true"),
            serviceAnswered: (document.querySelectorAll("[name=questions-2-a]:checked")[0].value === "true")
          },
          rank: {
            generalEvaluation: document.querySelectorAll("[name=questions-3-f]:checked")[0].value,
            location: document.querySelectorAll("[name=questions-3-b]:checked")[0].value,
            outInstallations: document.querySelectorAll("[name=questions-3-e]:checked")[0].value,
            serviceTime: document.querySelectorAll("[name=questions-3-d]:checked")[0].value,
            telephonicService: document.querySelectorAll("[name=questions-3-a]:checked")[0].value,
            waitingTime: document.querySelectorAll("[name=questions-3-c]:checked")[0].value
          },
          services: document.querySelectorAll("[name=questions-1]:checked")[0].value,
          sugestion: sugestion
        }});

        if(response.success === false) {
          const message = Error.getType({ type: response.error.details[0].message });
          if(message) {
            setAlertMessage(message.value);
          }
        }
        else {
          setAlertType("success");
          setAlertMessage("Pesquisa de satisfação enviada com sucesso.");
          form.reset();
          setValidated(false);
        }
      }

      setLoading(false);
    }
  };

  return (
    <Card className="section-form-satisfaction-survey">
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey="#item-tab-0" onSelect={k => setQuestion(k)} activeKey={question}>
          {
            questions.map((item, index) => (
              <Nav.Item key={item.id}>
                <Nav.Link href={`#item-tab-${index}`}>{index + 1}</Nav.Link>
              </Nav.Item>
            ))
          }
        </Nav>
      </Card.Header>
      <Card.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Tabs activeKey={question}>
            {
              questions.map((item, index) => (
                <Tab eventKey={`#item-tab-${index}`} key={item.id}>{item.content}</Tab>
              ))
            }
          </Tabs>
          {
            question === "#item-tab-3" ? (
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <div className="text-right">
                    <Button variant="dark" type="submit" disabled={loading ? true : false}>
                      {
                        loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )
                      }
                      { loading ? " Aguarde..." : " Enviar" }
                    </Button>
                  </div>
                </Form.Group>
              </Form.Row>
            ) : ""
          }
          {
            question === "#item-tab-3" ? (
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  {
                    alertMessage === "" ? "" : (
                      <Alert variant={alertType}>{alertMessage}</Alert>
                    )
                  }
                </Form.Group>
              </Form.Row>
            ) : ""
          }
        </Form>
      </Card.Body>
    </Card>
  );
}

export default SatisfactionSurvey;
