import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import MaskedFormControl from "react-bootstrap-maskedinput";

import "./index.css";

import Email from "../../../email";
import Error from "../../../error";

function Procuration() {
  const [validated, setValidated] = useState(false);
  const [address, setAddress] = useState("");
  const [book, setBook] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [comments, setComments] = useState("");
  const [dateAct, setDateAct] = useState("");
  const [email, setEmail] = useState("");
  const [interested, setInterested] = useState("");
  const [involvedAct, setInvolvedAct] = useState("");
  const [leaf, setLeaf] = useState("");
  const [protocol, setProtocol] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if(!loading) {
      setAlertType("danger");
      setAlertMessage("");
      setLoading(true);

      if (form.checkValidity() === false) {
        setAlertMessage("Preencha os campos obrigatórios.");
      }
      setValidated(true);

      const response = await Email.procuration({ data: {
        address,
        book,
        cellphone,
        comments,
        dateAct,
        email,
        interested,
        involvedAct,
        leaf,
        protocol
      }});

      if(response.success === false) {
        const message = Error.getType({ type: response.error.details[0].message });
        if(message) {
          setAlertMessage(message.value);
        }
      }
      else {
        setAlertType("success");
        setAlertMessage("E-mail enviado com sucesso.");
        form.reset();
      }
      setLoading(false);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Container className="zerar-padding">
        <Row>
          <Col xs={12} sm={6}>
            <h5 className="default-sub-title">Dados do pedido</h5>
            <div className="default-sub-title-separator half" />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Protocolo:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Número do protocolo"
                  onChange={e => setProtocol(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*Livro:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Número do livro"
                  onChange={e => setBook(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com o número do livro, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Data do ato:</Form.Label>
                <MaskedFormControl
                  type="text"
                  placeholder="Data do ato"
                  onChange={e => setDateAct(e.target.value)}
                  mask="11/11/1111"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*Folha:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Número da folha"
                  onChange={e => setLeaf(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com o número da folha, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*Envolvidos no ato:</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows="5"
                  placeholder=""
                  onChange={e => setInvolvedAct(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com os envolvidos no ato, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Col>
          <Col xs={12} sm={6}>
            <h5 className="default-sub-title">Dados do solicitante</h5>
            <div className="default-sub-title-separator half" />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*Interessado:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Interessado"
                  onChange={e => setInterested(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com o nome do interessado, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*Cidade/UF:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Ex: Camboriú/SC"
                  onChange={e => setAddress(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com a cidade/UF, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>E-mail:</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="E-mail"
                  onChange={e => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com um email válido, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Telefone | formato: (xx) 9xxxx-xxxx</Form.Label>
                <MaskedFormControl
                  required
                  type="text"
                  placeholder="Telefone"
                  onChange={e => setCellphone(e.target.value)}
                  mask="(11) 11111-1111"
                />
                <Form.Control.Feedback type="invalid">
                  Preencha com um telefone válido, é obrigatório.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Observações:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder=""
                  onChange={e => setComments(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
      </Container>
      <Form.Row>
        <Form.Group as={Col}>
          {
            alertMessage === "" ? "" : (
              <Alert variant={alertType}>{alertMessage}</Alert>
            )
          }
        </Form.Group>
      </Form.Row>
      <div className="text-right">
        <Button variant="dark" type="submit" disabled={loading ? true : false}>
          {
            loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <></>
            )
          }
          { loading ? " Aguarde..." : " Enviar" }
        </Button>
      </div>
    </Form>
  );
}

export default Procuration;
